import * as React from "react";
import styles from '../styles/suitcase.module.scss'

function SvgComponent(props) {
  return (
    <div className={styles.svg}>
      <svg viewBox="0 0 135 109" {...props}>
        <g
          transform="translate(-60 -334) translate(63 337)"
          stroke="none"
          strokeWidth={1}
          fill="none"
          fillRule="evenodd"
        >
          <path
            stroke="#102666"
            strokeWidth={5}
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M0.437897959 103.406588L128.571755 103.406588 128.571755 17.1302857 0.437897959 17.1302857z"
          />
          <path
            d="M128.572 50.233l-16.697 2.291a348.521 348.521 0 01-94.738 0L.438 50.233V17.13h128.134v33.103z"
            stroke="#102666"
            strokeWidth={5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            fill="#FFF"
            d="M28.0939184 65.5846723L36.4236327 65.5846723 36.4236327 44.6780504 28.0939184 44.6780504z"
          />
          <path
            stroke="#102666"
            strokeWidth={5}
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M28.0939184 65.5846723L36.4236327 65.5846723 36.4236327 44.6780504 28.0939184 44.6780504z"
          />
          <path
            fill="#FFF"
            d="M91.9533061 65.5846723L100.28302 65.5846723 100.28302 44.6780504 91.9533061 44.6780504z"
          />
          <path
            stroke="#102666"
            strokeWidth={5}
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M91.9533061 65.5846723L100.28302 65.5846723 100.28302 44.6780504 91.9533061 44.6780504z"
          />
          <path
            fill="#FFF"
            d="M32.2587755 17.1302857L96.1181633 17.1302857 96.1181633 0.43697479 32.2587755 0.43697479z"
          />
          <path
            stroke="#102666"
            strokeWidth={5}
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M32.2587755 17.1302857L96.1181633 17.1302857 96.1181633 0.43697479 32.2587755 0.43697479z"
          />
        </g>
      </svg>
    </div>
  );
}

export default SvgComponent;
