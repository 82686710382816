import * as React from "react";
import styles from '../styles/charity.module.scss';

function SvgComponent(props) {
  return (
    <div className={styles.svg}>
      <svg preserveAspectRatio="xMinYMin slice" viewBox="0 0 327 337" {...props} className="animate animate--draw">
        <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
          <path
            pathLength="1"
            d="M196.172 29.974c0 16.278-13.196 29.474-29.475 29.474-16.277 0-29.473-13.196-29.473-29.474S150.42.5 166.697.5c16.28 0 29.475 13.196 29.475 29.474zM111.3 135.006c0-30.596 24.802-55.399 55.397-55.399 30.597 0 55.4 24.803 55.4 55.4v37.387H111.3v-37.388z"
            stroke="#102666"
            strokeWidth={5}
            strokeLinecap="round"
            strokeLinejoin="round"
            transform="translate(-3 2)"
          />
          <path
            pathLength="1"
            d="M87.542 29.974c0 16.278-13.196 29.474-29.474 29.474S28.595 46.252 28.595 29.974 41.79.5 58.068.5s29.474 13.196 29.474 29.474"
            fill="#102666"
            transform="translate(-3 2)"
          />
          <path
            pathLength="1"
            d="M87.542 29.974c0 16.278-13.196 29.474-29.474 29.474S28.595 46.252 28.595 29.974 41.79.5 58.068.5s29.474 13.196 29.474 29.474z"
            stroke="#102666"
            strokeWidth={5}
            strokeLinecap="round"
            strokeLinejoin="round"
            transform="translate(-3 2)"
          />
          <path
            pathLength="1"
            d="M2.67 135.006c0-30.596 24.802-55.399 55.398-55.399 30.596 0 55.4 24.803 55.4 55.4v37.387H2.67v-37.388z"
            fill="#102666"
            transform="translate(-3 2)"
          />
          <path
            pathLength="1"
            d="M2.67 135.006c0-30.596 24.802-55.399 55.398-55.399 30.596 0 55.4 24.803 55.4 55.4v37.387H2.67v-37.388z"
            stroke="#102666"
            strokeWidth={5}
            strokeLinecap="round"
            strokeLinejoin="round"
            transform="translate(-3 2)"
          />
          <path
            pathLength="1"
            d="M306.971 29.974c0 16.278-13.196 29.474-29.475 29.474-16.277 0-29.473-13.196-29.473-29.474S261.219.5 277.496.5c16.279 0 29.475 13.196 29.475 29.474"
            fill="#D74EA5"
            transform="translate(-3 2)"
          />
          <path
            pathLength="1"
            d="M306.971 29.974c0 16.278-13.196 29.474-29.475 29.474-16.277 0-29.473-13.196-29.473-29.474S261.219.5 277.496.5c16.279 0 29.475 13.196 29.475 29.474z"
            stroke="#102666"
            strokeWidth={5}
            strokeLinecap="round"
            strokeLinejoin="round"
            transform="translate(-3 2)"
          />
          <path
            pathLength="1"
            d="M222.097 135.006c0-30.596 24.804-55.399 55.399-55.399 30.597 0 55.4 24.803 55.4 55.4v37.387H222.097v-37.388z"
            fill="#D74EA5"
            transform="translate(-3 2)"
          />
          <path
            pathLength="1"
            d="M222.097 135.006c0-30.596 24.804-55.399 55.399-55.399 30.597 0 55.4 24.803 55.4 55.4v37.387H222.097v-37.388z"
            stroke="#102666"
            strokeWidth={5}
            strokeLinecap="round"
            strokeLinejoin="round"
            transform="translate(-3 2)"
          />
          <path
            pathLength="1"
            d="M251.572 108.612c0 16.278-13.196 29.474-29.475 29.474-16.277 0-29.473-13.196-29.473-29.474s13.196-29.474 29.473-29.474c16.279 0 29.475 13.196 29.475 29.474"
            fill="#FFF"
            transform="translate(-3 2)"
          />
          <path
            pathLength="1"
            d="M251.572 108.612c0 16.278-13.196 29.474-29.475 29.474-16.277 0-29.473-13.196-29.473-29.474s13.196-29.474 29.473-29.474c16.279 0 29.475 13.196 29.475 29.474z"
            stroke="#102666"
            strokeWidth={5}
            strokeLinecap="round"
            strokeLinejoin="round"
            transform="translate(-3 2)"
          />
          <path
            pathLength="1"
            d="M166.697 213.644c0-30.596 24.804-55.399 55.4-55.399s55.4 24.803 55.4 55.4v59.743h-110.8v-59.744z"
            fill="#FFF"
            transform="translate(-3 2)"
          />
          <path
            pathLength="1"
            d="M166.697 213.644c0-30.596 24.804-55.399 55.4-55.399s55.4 24.803 55.4 55.4v59.743h-110.8v-59.744z"
            stroke="#102666"
            strokeWidth={5}
            strokeLinecap="round"
            strokeLinejoin="round"
            transform="translate(-3 2)"
          />
          <path
            pathLength="1"
            d="M332.898 79.14v58.94c-16.28 0-29.48-13.19-29.48-29.47s13.2-29.47 29.48-29.47"
            fill="#FFF"
            transform="translate(-3 2)"
          />
          <path
            pathLength="1"
            d="M332.898 79.14v58.94c-16.28 0-29.48-13.19-29.48-29.47s13.2-29.47 29.48-29.47z"
            stroke="#102666"
            strokeWidth={5}
            strokeLinecap="round"
            strokeLinejoin="round"
            transform="translate(-3 2)"
          />
          <path
            pathLength="1"
            d="M55.898 115.341H.498v-59.75c0-30.59 24.8-55.4 55.4-55.4"
            fill="#FFF"
            transform="translate(-3 2) translate(277 158.05)"
          />
          <path
            pathLength="1"
            d="M55.898 115.341H.498v-59.75c0-30.59 24.8-55.4 55.4-55.4"
            stroke="#102666"
            strokeWidth={5}
            strokeLinecap="round"
            strokeLinejoin="round"
            transform="translate(-3 2) translate(277 158.05)"
          />
          <path
            pathLength="1"
            d="M.898 59.011c.26.02.51.02.77.02 16.28 0 29.48-13.19 29.48-29.47S17.948.091 1.668.091c-.26 0-.51 0-.77.02"
            fill="#FFF"
            transform="translate(-3 2) translate(1 79.05)"
          />
          <path
            pathLength="1"
            d="M.898 59.011c.26.02.51.02.77.02 16.28 0 29.48-13.19 29.48-29.47S17.948.091 1.668.091c-.26 0-.51 0-.77.02"
            stroke="#102666"
            strokeWidth={5}
            strokeLinecap="round"
            strokeLinejoin="round"
            transform="translate(-3 2) translate(1 79.05)"
          />
          <path
            pathLength="1"
            d="M.898.201c.26-.01.51-.01.77-.01 30.6 0 55.4 24.81 55.4 55.4v59.75H.898"
            fill="#FFF"
            transform="translate(-3 2) translate(1 158.05)"
          />
          <path
            pathLength="1"
            d="M.898.201c.26-.01.51-.01.77-.01 30.6 0 55.4 24.81 55.4 55.4v59.75H.898"
            stroke="#102666"
            strokeWidth={5}
            strokeLinecap="round"
            strokeLinejoin="round"
            transform="translate(-3 2) translate(1 158.05)"
          />
          <path
            pathLength="1"
            d="M140.773 108.612c0 16.278-13.196 29.474-29.474 29.474s-29.474-13.196-29.474-29.474 13.196-29.474 29.474-29.474 29.474 13.196 29.474 29.474"
            fill="#38B9E5"
            transform="translate(-3 2)"
          />
          <path
            pathLength="1"
            d="M140.773 108.612c0 16.278-13.196 29.474-29.474 29.474s-29.474-13.196-29.474-29.474 13.196-29.474 29.474-29.474 29.474 13.196 29.474 29.474z"
            stroke="#102666"
            strokeWidth={5}
            strokeLinecap="round"
            strokeLinejoin="round"
            transform="translate(-3 2)"
          />
          <path
            pathLength="1"
            d="M55.9 213.644c0-30.596 24.803-55.399 55.399-55.399s55.399 24.803 55.399 55.4v67.673H55.9v-67.674z"
            fill="#38B9E5"
            transform="translate(-3 2)"
          />
          <path
            pathLength="1"
            d="M55.9 213.644c0-30.596 24.803-55.399 55.399-55.399s55.399 24.803 55.399 55.4v67.673H55.9v-67.674z"
            stroke="#102666"
            strokeWidth={5}
            strokeLinecap="round"
            strokeLinejoin="round"
            transform="translate(-3 2)"
          />
          <path
            pathLength="1"
            d="M196.172 184.436c0 16.278-13.196 29.475-29.475 29.475-16.277 0-29.473-13.197-29.473-29.475 0-16.278 13.196-29.474 29.473-29.474 16.28 0 29.475 13.197 29.475 29.475"
            fill="#FFF"
            transform="translate(-3 2)"
          />
          <path
            pathLength="1"
            d="M196.172 184.436c0 16.278-13.196 29.475-29.475 29.475-16.277 0-29.473-13.197-29.473-29.475 0-16.278 13.196-29.474 29.473-29.474 16.28 0 29.475 13.197 29.475 29.475z"
            stroke="#102666"
            strokeWidth={5}
            strokeLinecap="round"
            strokeLinejoin="round"
            transform="translate(-3 2)"
          />
          <path
            pathLength="1"
            d="M111.3 289.469c0-30.596 24.802-55.4 55.397-55.4 30.597 0 55.4 24.804 55.4 55.4v48.08H111.3v-48.08z"
            fill="#FFF"
            transform="translate(-3 2)"
          />
          <path
            pathLength="1"
            d="M111.3 289.469c0-30.596 24.802-55.4 55.397-55.4 30.597 0 55.4 24.804 55.4 55.4v48.08H111.3v-48.08z"
            stroke="#102666"
            strokeWidth={5}
            strokeLinecap="round"
            strokeLinejoin="round"
            transform="translate(-3 2)"
          />
          <path
            pathLength="1"
            d="M85.373 184.436c0 16.278-13.196 29.475-29.474 29.475s-29.474-13.197-29.474-29.475c0-16.278 13.196-29.474 29.474-29.474s29.474 13.197 29.474 29.475"
            fill="#FFF"
            transform="translate(-3 2)"
          />
          <path
            pathLength="1"
            d="M85.373 184.436c0 16.278-13.196 29.475-29.474 29.475s-29.474-13.197-29.474-29.475c0-16.278 13.196-29.474 29.474-29.474s29.474 13.197 29.474 29.475z"
            stroke="#102666"
            strokeWidth={5}
            strokeLinecap="round"
            strokeLinejoin="round"
            transform="translate(-3 2)"
          />
          <path
            pathLength="1"
            d="M.5 289.469c0-30.596 24.803-55.4 55.4-55.4 30.595 0 55.4 24.804 55.4 55.4v48.08H.5v-48.08z"
            fill="#FFF"
            transform="translate(-3 2)"
          />
          <path
            pathLength="1"
            d="M.5 289.469c0-30.596 24.803-55.4 55.4-55.4 30.595 0 55.4 24.804 55.4 55.4v48.08H.5v-48.08z"
            stroke="#102666"
            strokeWidth={5}
            strokeLinecap="round"
            strokeLinejoin="round"
            transform="translate(-3 2)"
          />
          <path
            pathLength="1"
            d="M306.971 184.436c0 16.278-13.196 29.475-29.475 29.475-16.277 0-29.473-13.197-29.473-29.475 0-16.278 13.196-29.474 29.473-29.474 16.279 0 29.475 13.197 29.475 29.475"
            fill="#A5CB25"
            transform="translate(-3 2)"
          />
          <path
            pathLength="1"
            d="M306.971 184.436c0 16.278-13.196 29.475-29.475 29.475-16.277 0-29.473-13.197-29.473-29.475 0-16.278 13.196-29.474 29.473-29.474 16.279 0 29.475 13.197 29.475 29.475z"
            stroke="#102666"
            strokeWidth={5}
            strokeLinecap="round"
            strokeLinejoin="round"
            transform="translate(-3 2)"
          />
          <path
            pathLength="1"
            d="M222.097 289.469c0-30.596 24.804-55.4 55.399-55.4 30.597 0 55.4 24.804 55.4 55.4v48.08H222.097v-48.08z"
            fill="#A5CB25"
            transform="translate(-3 2)"
          />
          <path
            pathLength="1"
            d="M222.097 289.469c0-30.596 24.804-55.4 55.399-55.4 30.597 0 55.4 24.804 55.4 55.4v48.08H222.097v-48.08z"
            stroke="#102666"
            strokeWidth={5}
            strokeLinecap="round"
            strokeLinejoin="round"
            transform="translate(-3 2)"
          />
        </g>
      </svg>
    </div>
  );
}

export default SvgComponent;
