import React from 'react'
import Handshake from './svgs/handshake.js'
import Percent from '../svgs/percent.js'
import Cog from '../svgs/cog.js'
import Compass from './svgs/compass.js'
import Bars from './svgs/bars.js'
import People from './svgs/people.js'

export default function Hero() {
  return (
    <section className="section">
      <div className="animate animate--section">
        <div className="blocks">
          <div className="block block-m-6w block-xl-4w block--orange block--text pad pad--s animate animate--fb">
            <h2 className="h-xl h-w-b">Futurebuilders England Fund</h2>
            <p>Futurebuilders England was a ground-breaking, Government-backed £142m social investment fund that provided loan financing to social sector organisations in England to help them bid for, win, and deliver public service contracts. Futurebuilders was the first social investment fund in the UK.</p>
          </div>

          <div className="block block-m-6w block-xl-4w blocks">
            <div className="block block-4w block-3h animate animate--fl"></div>
            <div className="block block-8w block-6h icon pad-top pad-top--s pad-bottom pad-bottom--s animate animate--fb animate--check-safari">
              <Handshake/>
            </div>
            <div className="block block-4w block-3h icon pad animate animate--fr animate--check-safari">
              <Percent/>
            </div>
            <div className="block block-4w block-3h block--green animate animate--fl"></div>
            <div className="block block-4w block-3h animate animate--fb"></div>
            <div className="block block-4w block-3h icon pad animate animate--fr animate--check-safari">
              <Cog/>
            </div>
            <div className="block block-4w block-3h animate animate--fl"></div>
            <div className="block block-4w block-3h icon pad animate animate--fb animate--check-safari">
              <Compass/>
            </div>
            <div className="block block-4w block-3h animate animate--fb"></div>
          </div>

          <div className="block block-m-6w block-xl-4w blocks">
            <div className="block block-4w block-3h block--navy"></div>
            <div className="block block-8w block-3h icon icon--bottom pad-top pad-top--s pad-left pad-right">
              <Bars/>
            </div>
            <div className="block block-9h icon icon--bottom pad-top pad-top--s pad-left pad-left--s animate animate--fr animate--check-safari">
              <People/>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
