import * as React from "react";
import styles from '../styles/bars.module.scss';

function Bars(props) {
  return (
    <div className={styles.svg}>
      <svg viewBox="0 0 210 110" {...props} className="animate animate--bars">
        <g
          fill="none"
          fillRule="evenodd"
          stroke="#102666"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={5}
          transform="translate(3 3)"
        >
          <path d="M0 109.878L28.501 109.878 28.501 76.753 0 76.753z" />
          <path d="M43.852 109.878L72.354 109.878 72.354 43.628 43.852 43.628z" />
          <path d="M87.704 109.878L116.206 109.878 116.206 53.42 87.704 53.42z" />
          <path d="M131.556 109.878L160.057 109.878 160.057 26.042 131.556 26.042z" />
          <path d="M175.408 109.878L203.91 109.878 203.91 0 175.408 0z" />
        </g>
      </svg>
    </div>
  );
}

export default Bars;
