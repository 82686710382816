import * as React from "react";

function Cog(props) {
  return (
    <div className="cog">
      <svg viewBox="0 0 93 93" {...props} className="animate animate--draw">
        <g
          fill="none"
          fillRule="evenodd"
          stroke="#102666"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={5}
        >
          <path
            pathLength="1"
            d="M55.467 55.467c-6.6 6.6-17.297 6.6-23.896 0-6.598-6.599-6.598-17.297 0-23.896 6.599-6.599 17.297-6.599 23.896 0 6.599 6.6 6.599 17.297 0 23.896z"
            transform="translate(2.731 2.731)"
          />
          <path
            pathLength="1"
            d="M87.037 50.968V36.07l-11.772-1.828a32.827 32.827 0 00-2.737-6.614l7.03-9.614L69.023 7.48l-9.613 7.03a32.827 32.827 0 00-6.614-2.737L50.968 0H36.069l-1.828 11.773a32.818 32.818 0 00-6.613 2.737l-9.614-7.03L7.479 18.014l7.03 9.614a32.881 32.881 0 00-2.736 6.614L0 36.07v14.898l11.773 1.828a32.857 32.857 0 002.736 6.614l-7.03 9.614 10.535 10.535 9.614-7.03a32.873 32.873 0 006.613 2.736l1.828 11.773h14.899l1.828-11.773a32.905 32.905 0 006.614-2.736l9.613 7.03 10.535-10.535-7.03-9.614a32.803 32.803 0 002.737-6.614l11.772-1.828z"
            transform="translate(2.731 2.731)"
          />
        </g>
      </svg>
    </div>
  );
}

export default Cog;
