import * as React from "react";
import styles from '../styles/modal-1-pie-4.module.scss'

function SvgComponent(props) {
  return (
    <div className={styles.svg}>
      <svg viewBox="0 0 166 166" {...props}>
        <g fill="none" fillRule="evenodd" stroke="#102666" strokeWidth={5}>
          <path
            fill="#D8D8D8"
            d="M80 0c44.183 0 80 35.817 80 80s-35.817 80-80 80S0 124.183 0 80 35.817 0 80 0zm0 37c-23.748 0-43 19.252-43 43s19.252 43 43 43 43-19.252 43-43-19.252-43-43-43z"
            transform="translate(3 3)"
          />
          <path
            fill="#A5CB25"
            d="M80 0l.151.001.001 37L80 37c-17.802 0-33.077 10.818-39.61 26.238L6.44 48.5C18.67 19.98 47.002 0 80 0z"
            transform="translate(3 3)"
          />
        </g>
      </svg>
    </div>
  );
}

export default SvgComponent;
