import * as React from "react";
import styles from '../styles/liabilities.module.scss';

function SvgComponent(props) {
  return (
    <div className={styles.svg}>
      <svg viewBox="0 0 177 176" {...props} className="animate animate--draw">
        <g
          transform="translate(-1184 -1154) translate(1162 1131) translate(25 26)"
          stroke="none"
          strokeWidth={1}
          fill="none"
          fillRule="evenodd"
        >
          <path
            pathLength="1"
            d="M133.598 84.873c0 26.223-21.258 47.481-47.48 47.481-26.225 0-47.483-21.258-47.483-47.481 0-26.223 21.258-47.481 47.482-47.481 26.223 0 47.481 21.258 47.481 47.481"
            fill="#A5CB25"
          />
          <path
            pathLength="1"
            d="M133.598 84.873c0 26.223-21.258 47.481-47.48 47.481-26.225 0-47.483-21.258-47.483-47.481 0-26.223 21.258-47.481 47.482-47.481 26.223 0 47.481 21.258 47.481 47.481z"
            stroke="#102666"
            strokeWidth={5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            pathLength="1"
            d="M96.745 68.7c-1.435-1.268-3.492-1.468-5.402-1.608-2.664-.196-5.56-.333-7.766 1.172-2.81 1.915-3.456 5.74-3.586 9.138-.326 8.532.989 17.42-2.127 25.37h18.378"
            stroke="#102666"
            strokeWidth={5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            pathLength="1"
            stroke="#102666"
            strokeWidth={5}
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M72.15 86.3627L91.635 86.3627"
          />
          <path
            pathLength="1"
            stroke="#102666"
            strokeWidth={5}
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M95.3844 160.4779L86.1164 169.7459 76.8504 160.4779"
          />
          <path
            pathLength="1"
            stroke="#102666"
            strokeWidth={5}
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M86.1168 146.0492L86.1168 168.9902"
          />
          <path
            pathLength="1"
            stroke="#102666"
            strokeWidth={5}
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M131.9449 145.7153L128.5529 158.3743 115.8939 154.9823"
          />
          <path
            pathLength="1"
            stroke="#102666"
            strokeWidth={5}
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M116.7047 137.8529L128.1757 157.7209"
          />
          <path
            pathLength="1"
            stroke="#102666"
            strokeWidth={5}
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M156.2262 114.6498L159.6192 127.3088 146.9592 130.7008"
          />
          <path
            pathLength="1"
            stroke="#102666"
            strokeWidth={5}
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M139.0973 115.4609L158.9653 126.9319"
          />
          <path
            pathLength="1"
            stroke="#102666"
            strokeWidth={5}
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M161.7223 75.6059L170.9893 84.8729 161.7223 94.1399"
          />
          <path
            pathLength="1"
            stroke="#102666"
            strokeWidth={5}
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M147.2926 84.873L170.2346 84.873"
          />
          <path
            pathLength="1"
            stroke="#102666"
            strokeWidth={5}
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M146.9596 39.0448L159.6186 42.4368 156.2266 55.0958"
          />
          <path
            pathLength="1"
            stroke="#102666"
            strokeWidth={5}
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M139.0973 54.2851L158.9653 42.8141"
          />
          <path
            pathLength="1"
            stroke="#102666"
            strokeWidth={5}
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M115.8942 14.7636L128.5532 11.3716 131.9452 24.0306"
          />
          <path
            pathLength="1"
            stroke="#102666"
            strokeWidth={5}
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M116.7047 31.893L128.1757 12.025"
          />
          <path
            pathLength="1"
            stroke="#102666"
            strokeWidth={5}
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M76.8502 9.268L86.1172 0 95.3842 9.268"
          />
          <path
            pathLength="1"
            stroke="#102666"
            strokeWidth={5}
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M86.1168 23.6967L86.1168 0.7557"
          />
          <path
            pathLength="1"
            stroke="#102666"
            strokeWidth={5}
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M40.2887 24.0307L43.6817 11.3717 56.3397 14.7637"
          />
          <path
            pathLength="1"
            stroke="#102666"
            strokeWidth={5}
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M55.5289 31.893L44.0579 12.025"
          />
          <path
            pathLength="1"
            stroke="#102666"
            strokeWidth={5}
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M16.0074 55.0961L12.6154 42.4371 25.2744 39.0451"
          />
          <path
            pathLength="1"
            stroke="#102666"
            strokeWidth={5}
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M33.1373 54.2851L13.2693 42.8141"
          />
          <path
            pathLength="1"
            stroke="#102666"
            strokeWidth={5}
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M24.941 84.873L2 84.873"
          />
          <path
            pathLength="1"
            stroke="#102666"
            strokeWidth={5}
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M25.274 130.7011L12.616 127.3091 16.007 114.6501"
          />
          <path
            pathLength="1"
            stroke="#102666"
            strokeWidth={5}
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M33.1373 115.4609L13.2693 126.9319"
          />
          <path
            pathLength="1"
            stroke="#102666"
            strokeWidth={5}
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M56.3395 154.9823L43.6815 158.3743 40.2885 145.7153"
          />
          <path
            pathLength="1"
            stroke="#102666"
            strokeWidth={5}
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M55.5289 137.8529L44.0579 157.7209"
          />
          <path
            pathLength="1"
            stroke="#102666"
            strokeWidth={5}
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M9 94L0 84.5 9 75"
          />
        </g>
      </svg>
    </div>
  );
}

export default SvgComponent;
