import * as React from "react";
import styles from '../styles/city.module.scss';

function SvgComponent(props) {
  return (
    <div className={styles.svg}>
      <svg preserveAspectRatio="xMinYMin slice" viewBox="0 0 363 326" {...props} className="animate animate--draw">
        <g
          transform="translate(2 2)"
          stroke="none"
          strokeWidth={1}
          fill="none"
          fillRule="evenodd"
        >
          <path
            pathLength="1"
            stroke="#102666"
            strokeWidth={5}
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M174.6626 0.4997L43.8916 76.5557 43.8916 326.4967 174.6626 326.4967z"
          />
          <path
            pathLength="1"
            fill="#0079B9"
            d="M70.009 326.497L105.405 326.497 105.405 260.687 70.009 260.687z"
          />
          <path
            pathLength="1"
            stroke="#102666"
            strokeWidth={5}
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M70.009 326.497L105.405 326.497 105.405 260.687 70.009 260.687z"
          />
          <path
            pathLength="1"
            fill="#FFF"
            d="M153.327 326.496L284.098 326.496 284.098 130.829 153.327 130.829z"
          />
          <path
            pathLength="1"
            stroke="#102666"
            strokeWidth={5}
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M153.327 326.496L284.098 326.496 284.098 130.829 153.327 130.829z"
          />
          <path
            pathLength="1"
            fill="#FFF"
            d="M271.089 326.496L363.804 326.496 363.804 233.782 271.089 233.782z"
          />
          <g transform="translate(0 93.558)">
            <path
              pathLength="1"
              stroke="#102666"
              strokeWidth={5}
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M271.089 232.938L363.804 232.938 363.804 140.224 271.089 140.224z"
            />
            <path
              pathLength="1"
              d="M218.712 167.129c-9.774 0-17.697 7.924-17.697 17.698v48.112h35.395v-48.112c0-9.774-7.924-17.698-17.698-17.698"
              fill="#D74EA5"
            />
            <path
              pathLength="1"
              d="M218.712 167.129h0c-9.774 0-17.697 7.924-17.697 17.698v48.112h35.395v-48.112c0-9.774-7.924-17.698-17.698-17.698z"
              stroke="#102666"
              strokeWidth={5}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              pathLength="1"
              d="M331.76 179.713c-7.905 0-14.313 6.408-14.313 14.314v38.911h28.626v-38.91c0-7.907-6.408-14.315-14.313-14.315"
              fill="#DB8725"
            />
            <path
              pathLength="1"
              d="M331.76 179.713h0c-7.905 0-14.313 6.408-14.313 14.314v38.911h28.626v-38.91c0-7.907-6.408-14.315-14.313-14.315z"
              stroke="#102666"
              strokeWidth={5}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              pathLength="1"
              stroke="#102666"
              strokeWidth={5}
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M169.031 147.824L192.569 147.824 192.569 111.313 169.031 111.313z"
            />
            <path
              pathLength="1"
              stroke="#102666"
              strokeWidth={5}
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M206.944 147.824L230.482 147.824 230.482 111.313 206.944 111.313z"
            />
            <path
              pathLength="1"
              stroke="#102666"
              strokeWidth={5}
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M244.857 147.824L268.395 147.824 268.395 111.313 244.857 111.313z"
            />
            <path
              pathLength="1"
              stroke="#102666"
              strokeWidth={5}
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M169.031 91.545L192.569 91.545 192.569 55.034 169.031 55.034z"
            />
            <path
              pathLength="1"
              stroke="#102666"
              strokeWidth={5}
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M206.944 91.545L230.482 91.545 230.482 55.034 206.944 55.034z"
            />
            <path
              pathLength="1"
              stroke="#102666"
              strokeWidth={5}
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M244.857 91.545L268.395 91.545 268.395 55.034 244.857 55.034z"
            />
            <path
              pathLength="1"
              fill="#DB8725"
              d="M262.681 140.224L372.212 140.224 372.212 121.596 262.681 121.596z"
            />
            <path
              pathLength="1"
              stroke="#102666"
              strokeWidth={5}
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M262.681 140.224L372.212 140.224 372.212 121.596 262.681 121.596z"
            />
            <path
              pathLength="1"
              fill="#D74EA5"
              d="M147.19 37.644L290.236 37.644 290.236 19.016 147.19 19.016z"
            />
            <path
              pathLength="1"
              stroke="#102666"
              strokeWidth={5}
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M147.19 37.644L290.236 37.644 290.236 19.016 147.19 19.016z"
            />
            <path
              pathLength="1"
              stroke="#102666"
              strokeWidth={5}
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M284.5 181.581L308.037 181.581 308.037 156.136 284.5 156.136z"
            />
            <path
              pathLength="1"
              fill="#0079B9"
              d="M59.595 37.271L121.045 37.271 121.045 0.76 59.595 0.76z"
            />
            <path
              pathLength="1"
              stroke="#102666"
              strokeWidth={5}
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M59.595 37.271L121.045 37.271 121.045 0.76 59.595 0.76z"
            />
            <path
              pathLength="1"
              stroke="#102666"
              strokeWidth={5}
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M105.405 130.909L136.687 130.909 136.687 78.773 105.405 78.773z"
            />
            <path
              pathLength="1"
              stroke="#102666"
              strokeWidth={5}
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M26.7671 179.1516L26.7671 233.4996"
            />
            <path
              pathLength="1"
              d="M53.034 179.152c0 14.507-11.761 26.267-26.267 26.267S.5 193.659.5 179.152s11.76-26.267 26.266-26.267c14.506 0 26.267 11.76 26.267 26.267"
              fill="#A5CB25"
            />
            <path
              pathLength="1"
              d="M53.034 179.152c0 14.507-11.761 26.267-26.267 26.267S.5 193.659.5 179.152s11.76-26.267 26.266-26.267c14.506 0 26.267 11.76 26.267 26.267z"
              stroke="#102666"
              strokeWidth={5}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
        </g>
      </svg>
    </div>
  );
}

export default SvgComponent;
