import React from 'react'
import City from './svgs/city.js'
import Chain from './svgs/chain.js'
import People from './svgs/people.js'
import Cog from '../svgs/cog.js'
import Percent from '../svgs/percent.js'

const Future = (props) => {
  return (
    <section className="section" id="future">
      <div className="animate animate--section">
        <div className="section__tab">
          <h3 className="h-m h-w-b">The Future</h3>
        </div>
        <div className="blocks blocks--tab">
          <div className="block block-l-6w blocks">
            <div className="block block--text pad pad--s animate animate--fb">
              <h2 className="h-xl">What's next?</h2>
            </div>
            <div className="block block--text pad pad--s pad--m animate animate--fl">
              <p>COVID-19 has exposed the need for a new approach to how we invest in places and people.</p>
              <p>The pandemic has exacerbated inequalities between and within communities. The places that were already at-risk – due to levels of deprivation, job insecurity, or low wages – will struggle to bounce back.</p>
              <p>In the recovery, the social economy must and will play a vital role in helping the hardest hit places to rebuild.</p>
              <p>A blended social investment fund on the scale of Futurebuilders has not been repeated in the market since. It is important that these learnings from Futurebuilders and other available data sets are used to make new patient, flexible funds fairer and more effective for those most in need.</p>
            </div>
          </div>
          <div className="block block-l-6w blocks">
            <div className="block block-l-7w icon icon--bottom pad-top pad-top--s animate animate--fr animate--check-safari">
              <City/>
            </div>
            <div className="block block-l-5w blocks">
              <div className="block block-6w icon pad animate animate--fl animate--check-safari">
                <Cog/>
              </div>
              <div className="block block-6w block--navy"></div>
              <div className="block block-6w animate animate--fb"></div>
              <div className="block block-6w animate animate--fr"></div>
              <div className="block block-6w block--green animate animate--fl"></div>
              <div className="block block-6w animate animate--ft"></div>
            </div>
            <div className="block block-2w animate animate--fb"></div>
            <div className="block block-2w block--blue animate animate--ft"></div>
            <div className="block block-2w animate animate--fl"></div>
            <div className="block block-2w animate animate--fr"></div>
            <div className="block block-4w icon pad animate animate--fb animate--check-safari">
              <Chain/>
            </div>
            <div className="block block-l-6w blocks">
              <div className="block block-6w animate animate--ft"></div>
              <div className="block block-6w animate animate--fr"></div>
              <div className="block block-6w block--pink animate animate--fl"></div>
              <div className="block block-6w animate animate--ft"></div>
              <div className="block block-6w animate animate--fb"></div>
              <div className="block block-6w icon pad animate animate--fr animate--check-safari">
                <Percent/>
              </div>
            </div>
            <div className="block block-l-6w icon icon--left icon--bottom pad-top pad-top--s animate animate--fb animate--check-safari">
              <People/>
            </div>
          </div>
        </div>
        <a href="#page" onClick={props.scrollPage} className="section__tab section__tab--bottom">
          <h3 className="h-m h-w-b">
            Back to top
          </h3>
        </a>
      </div>
    </section>
  )
}

export default Future
