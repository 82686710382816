import * as React from "react";
import styles from '../styles/profit.module.scss';

function SvgComponent(props) {
  return (
    <div className={styles.svg}>
      <svg viewBox="0 0 160 129" {...props} className="animate animate--draw">
        <g
          transform="translate(-983 -1171) translate(986 1174)"
          stroke="none"
          strokeWidth={1}
          fill="none"
          fillRule="evenodd"
        >
          <path pathLength="1" fill="#DB8725" d="M0 123L34 123 34 74 0 74z" />
          <path
            pathLength="1"
            stroke="#102666"
            strokeWidth={4.241}
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M0 123L34 123 34 74 0 74z"
          />
          <path pathLength="1" fill="#DB8725" d="M48 123L82 123 82 52 48 52z" />
          <path
            pathLength="1"
            stroke="#102666"
            strokeWidth={4.241}
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M48 123L82 123 82 52 48 52z"
          />
          <path pathLength="1" fill="#DB8725" d="M95 123L129 123 129 26 95 26z" />
          <path
            pathLength="1"
            stroke="#102666"
            strokeWidth={4.241}
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M95 123L129 123 129 26 95 26z"
          />
          <path
            pathLength="1"
            d="M154 71c0 13.807-11.193 25-25.001 25C115.192 96 104 84.807 104 71s11.192-25 24.999-25C142.807 46 154 57.193 154 71"
            fill="#FFF"
          />
          <path
            pathLength="1"
            d="M154 71c0 13.807-11.193 25-25.001 25C115.192 96 104 84.807 104 71s11.192-25 24.999-25C142.807 46 154 57.193 154 71z"
            stroke="#102666"
            strokeWidth={5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            pathLength="1"
            d="M135 61.917c-.76-.673-1.85-.78-2.861-.855-1.411-.103-2.944-.176-4.113.623-1.488 1.016-1.83 3.047-1.899 4.85-.173 4.529.523 9.245-1.127 13.465h9.733"
            stroke="#102666"
            strokeWidth={5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            pathLength="1"
            stroke="#102666"
            strokeWidth={5}
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M0 58L48.8863373 16.2483402 61.0282965 25.0653617 88 0"
          />
          <path
            pathLength="1"
            stroke="#102666"
            strokeWidth={5}
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M71 0.00916332275L87.9908422 0 88 17"
          />
          <path
            pathLength="1"
            stroke="#102666"
            strokeWidth={5}
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M122 71.5L132 71.5"
          />
        </g>
      </svg>
    </div>
  );
}

export default SvgComponent;
