import * as React from "react";
import styles from '../styles/arrow.module.scss';

function SvgComponent(props) {
  return (
    <div className={styles.svg}>
      <svg viewBox="0 0 60 69" {...props} className="animate animate--draw">
        <g
          transform="translate(-624 -1033) translate(627 1036)"
          stroke="#102666"
          strokeWidth={5}
          fill="none"
          fillRule="evenodd"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path pathLength="1" d="M54 36L27.0004972 63 0 36" />
          <path pathLength="1" d="M27.5 61L27.5 0" />
        </g>
      </svg>
    </div>
  );
}

export default SvgComponent;
