import * as React from "react";
import styles from '../styles/flexibility.module.scss';

function SvgComponent(props) {
  return (
    <div className={styles.svg}>
      <svg preserveAspectRatio="xMinYMin slice" viewBox="0 0 341 348" {...props} className="animate animate--draw-flexibility">
        <g
          transform="translate(21 -3)"
          stroke="none"
          strokeWidth={1}
          fill="none"
          fillRule="evenodd"
        >
          <path
            pathLength="1"
            className="draw-path"
            stroke="#102666"
            strokeWidth={5}
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M51.4653 294.6266L131.6273 214.4646"
          />
          <path
            pathLength="1"
            className="draw-path"
            stroke="#102666"
            strokeWidth={5}
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M105.1733 213.1451L132.5023 213.1451 132.5023 240.4741"
          />
          <g transform="translate(0 .002)">
            <path
              stroke="#102666"
              strokeWidth={5}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeDasharray={12}
              d="M192.7847 153.3047L317.4007 28.6897"
            />
            <path
              pathLength="1"
              className="draw-path"
              d="M11.649 262.573l8.148-8.148c15.907-15.906 15.907-41.695 0-57.601-15.906-15.905-15.906-41.694 0-57.6l27.453-27.452M57.455 353.5l95.265-92.677c13.686-13.686 35.877-13.686 49.563 0 13.687 13.687 35.876 13.687 49.563 0l30.148-30.148"
              stroke="#102666"
              strokeWidth={5}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              pathLength="1"
              className="draw-path"
              d="M233.931 337.068c0 5.83-1.189 11.39-3.34 16.43h-77.42a41.797 41.797 0 01-3.34-16.43c0-23.22 18.83-42.05 42.05-42.05s42.05 18.83 42.05 42.05"
              fill="#DB8725"
            />
            <path
              pathLength="1"
              className="draw-path"
              d="M233.931 337.068c0 5.83-1.189 11.39-3.34 16.43h-77.42a41.797 41.797 0 01-3.34-16.43c0-23.22 18.83-42.05 42.05-42.05s42.05 18.83 42.05 42.05z"
              stroke="#102666"
              strokeWidth={5}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M35.75 319.446c0 9.734-7.892 17.625-17.625 17.625S.5 329.181.5 319.446c0-9.734 7.892-17.625 17.625-17.625s17.626 7.891 17.626 17.625zM142.82 46.315c0 9.733-7.891 17.625-17.624 17.625-9.734 0-17.625-7.892-17.625-17.625 0-9.734 7.89-17.625 17.625-17.625 9.733 0 17.625 7.89 17.625 17.625z"
              stroke="#102666"
              strokeWidth={5}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeDasharray="11.071,11.071"
            />
            <path
              pathLength="1"
              className="draw-path"
              d="M179.37 183.309c0 9.734-7.892 17.625-17.625 17.625-9.734 0-17.625-7.891-17.625-17.625s7.89-17.625 17.625-17.625c9.733 0 17.625 7.89 17.625 17.625"
              fill="#D74EA5"
            />
            <path
              pathLength="1"
              className="draw-path"
              d="M179.37 183.309c0 9.734-7.892 17.625-17.625 17.625-9.734 0-17.625-7.891-17.625-17.625s7.89-17.625 17.625-17.625c9.733 0 17.625 7.89 17.625 17.625z"
              stroke="#102666"
              strokeWidth={5}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              pathLength="1"
              className="draw-path"
              d="M269.101 16.228c0 23.86-19.34 43.21-43.199 43.21-23.861 0-43.211-19.35-43.211-43.21 0-5.55 1.051-10.86 2.961-15.73h80.5a43.228 43.228 0 012.949 15.73"
              fill="#A5CB25"
            />
            <path
              pathLength="1"
              className="draw-path"
              d="M269.101 16.228c0 23.86-19.34 43.21-43.199 43.21-23.861 0-43.211-19.35-43.211-43.21 0-5.55 1.051-10.86 2.961-15.73h80.5a43.228 43.228 0 012.949 15.73z"
              stroke="#102666"
              strokeWidth={5}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              pathLength="1"
              className="draw-path"
              stroke="#102666"
              strokeWidth={5}
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M247.5747 152.2239L280.0517 184.7009"
            />
            <path
              pathLength="1"
              className="draw-path"
              stroke="#102666"
              strokeWidth={5}
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M280.0512 152.2239L247.5742 184.7009"
            />
            <path
              pathLength="1"
              className="draw-path"
              stroke="#102666"
              strokeWidth={5}
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M55.6548 166.0906L88.1318 198.5676"
            />
            <path
              pathLength="1"
              className="draw-path"
              stroke="#102666"
              strokeWidth={5}
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M88.1313 166.0906L55.6543 198.5676"
            />
            <path
              pathLength="1"
              className="draw-path"
              stroke="#102666"
              strokeWidth={5}
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M10.0317 42.7884L14.2747 47.0314"
            />
            <path
              stroke="#102666"
              strokeWidth={5}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeDasharray="11.31,11.31"
              d="M22.272 55.0281L34.267 67.0241"
            />
            <path
              pathLength="1"
              className="draw-path"
              stroke="#102666"
              strokeWidth={5}
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M38.2661 71.0223L42.5081 75.2653"
            />
            <path
              pathLength="1"
              className="draw-path"
              stroke="#102666"
              strokeWidth={5}
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M42.5083 42.7884L38.2663 47.0314"
            />
            <path
              stroke="#102666"
              strokeWidth={5}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeDasharray="11.31,11.31"
              d="M30.269 55.0281L18.273 67.0241"
            />
            <path
              pathLength="1"
              className="draw-path"
              stroke="#102666"
              strokeWidth={5}
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M14.2749 71.0223L10.0319 75.2653"
            />
            <path
              pathLength="1"
              className="draw-path"
              stroke="#102666"
              strokeWidth={5}
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M21.2681 109.9268L48.5971 109.9268 48.5971 137.2558"
            />
            <path
              pathLength="1"
              className="draw-path"
              stroke="#102666"
              strokeWidth={5}
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M255.6792 229.8387L283.0082 229.8387 283.0082 257.1677"
            />
            <path
              pathLength="1"
              className="draw-path"
              d="M81.368 116.362l16.409-16.409c10.912-10.913 28.605-10.913 39.517 0 10.912 10.912 28.603 10.912 39.515 0l15.07-15.07"
              stroke="#102666"
              strokeWidth={5}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              pathLength="1"
              className="draw-path"
              stroke="#102666"
              strokeWidth={5}
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M166.228 83.5557L193.557 83.5557 193.557 110.8847"
            />
          </g>
        </g>
      </svg>
    </div>
  );
}

export default SvgComponent;
