import * as React from "react";
import styles from '../styles/loan.module.scss';

function SvgComponent(props) {
  return (
    <div className={styles.svg}>
      <svg viewBox="0 0 327 229" {...props} className="animate animate--draw">
        <defs>
          <path
            pathLength="1" id="loan-a" d="M0 234h335.024V.496H0z" />
        </defs>
        <g fill="none" fillRule="evenodd">
          <path
            pathLength="1" fill="#DB8725" d="M-1.988 231.492h331v-70.573h-331z" />
          <path
            pathLength="1"
            stroke="#102666"
            strokeWidth={4.024}
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M-1.988 231.492h331v-70.573h-331z"
          />
          <path
            pathLength="1" fill="#DB8725" d="M26.449 160.554h142.314V87.851H26.449z" />
          <path
            pathLength="1"
            stroke="#102666"
            strokeWidth={4.024}
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M26.449 160.554h142.314V87.851H26.449z"
          />
          <g transform="translate(-4 -.496)">
            <path
              pathLength="1"
              d="M172.762 109.143c-11.485 0-20.796-9.31-20.796-20.796m0 72.706c0-11.486 9.31-20.796 20.796-20.796m-141.981-.001c11.485 0 20.796 9.311 20.796 20.796m0-72.705c0 11.485-9.31 20.796-20.796 20.796M152.368 124.7a6.41 6.41 0 11-12.82 0 6.41 6.41 0 0112.82 0zm-26.287 0c0 13.518-10.958 24.476-24.476 24.476-13.517 0-24.475-10.958-24.475-24.476 0-13.518 10.958-24.476 24.475-24.476 13.518 0 24.476 10.958 24.476 24.476zm-62.418 0a6.41 6.41 0 11-12.82 0 6.41 6.41 0 0112.82 0z"
              stroke="#102666"
              strokeWidth={4.024}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              pathLength="1"
              d="M108.875 113.115c-.95-.84-2.313-.973-3.578-1.065-1.764-.13-3.68-.22-5.143.775-1.86 1.27-2.289 3.802-2.375 6.052-.216 5.651.656 11.537-1.408 16.802h12.171m-15.955-10.867h12.904"
              stroke="#102666"
              strokeWidth={4.024}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <mask id="loan-b" fill="#fff">
              <use xlinkHref="#loan-a" />
            </mask>
            <path
              pathLength="1"
              fill="#FFF"
              mask="url(#loan-b)"
              d="M30.449 147.904h142.314V75.201H30.449z"
            />
            <path
              pathLength="1"
              stroke="#102666"
              strokeWidth={4.024}
              strokeLinecap="round"
              strokeLinejoin="round"
              mask="url(#loan-b)"
              d="M30.449 147.904h142.314V75.201H30.449zm142.313-51.906c-11.485 0-20.796-9.311-20.796-20.796m0 72.705c0-11.486 9.31-20.796 20.796-20.796m-141.981-.001c11.485 0 20.796 9.312 20.796 20.796m0-72.705c0 11.486-9.31 20.796-20.796 20.796"
            />
            <path
              pathLength="1"
              d="M152.368 111.554a6.41 6.41 0 11-12.82 0 6.41 6.41 0 0112.82 0zm-26.287 0c0 13.518-10.958 24.476-24.476 24.476-13.517 0-24.475-10.958-24.475-24.476 0-13.518 10.958-24.476 24.475-24.476 13.518 0 24.476 10.958 24.476 24.476zm-62.418 0a6.41 6.41 0 11-12.82 0 6.41 6.41 0 0112.82 0z"
              stroke="#102666"
              strokeWidth={4.024}
              strokeLinecap="round"
              strokeLinejoin="round"
              mask="url(#loan-b)"
            />
            <path
              pathLength="1"
              d="M108.875 99.97c-.95-.84-2.313-.973-3.578-1.065-1.764-.13-3.68-.221-5.143.775-1.86 1.269-2.289 3.802-2.375 6.052-.216 5.65.656 11.536-1.408 16.8h12.171m-15.955-10.866h12.904"
              stroke="#102666"
              strokeWidth={4.024}
              strokeLinecap="round"
              strokeLinejoin="round"
              mask="url(#loan-b)"
            />
            <path
              pathLength="1"
              fill="#A5CB25"
              mask="url(#loan-b)"
              d="M30.449 132.874h142.314V60.171H30.449z"
            />
            <path
              pathLength="1"
              stroke="#102666"
              strokeWidth={4.024}
              strokeLinecap="round"
              strokeLinejoin="round"
              mask="url(#loan-b)"
              d="M30.449 132.874h142.314V60.171H30.449zm142.313-51.907c-11.485 0-20.796-9.311-20.796-20.796m0 72.705c0-11.485 9.31-20.796 20.796-20.796m-141.981 0c11.485 0 20.796 9.31 20.796 20.795m0-72.705c0 11.486-9.31 20.796-20.796 20.796"
            />
            <path
              pathLength="1"
              d="M152.368 96.523a6.41 6.41 0 11-12.82 0 6.41 6.41 0 0112.82 0zm-26.287 0c0 13.517-10.958 24.476-24.476 24.476-13.517 0-24.475-10.959-24.475-24.476 0-13.518 10.958-24.476 24.475-24.476 13.518 0 24.476 10.958 24.476 24.476zm-62.418 0a6.41 6.41 0 11-12.82 0 6.41 6.41 0 0112.82 0z"
              stroke="#102666"
              strokeWidth={4.024}
              strokeLinecap="round"
              strokeLinejoin="round"
              mask="url(#loan-b)"
            />
            <path
              pathLength="1"
              d="M108.875 84.939c-.95-.84-2.313-.972-3.578-1.065-1.764-.13-3.68-.221-5.143.775-1.86 1.269-2.289 3.802-2.375 6.052-.216 5.65.656 11.537-1.408 16.802h12.171M92.587 96.635h12.904"
              stroke="#102666"
              strokeWidth={4.024}
              strokeLinecap="round"
              strokeLinejoin="round"
              mask="url(#loan-b)"
            />
            <path
              pathLength="1"
              fill="#102666"
              mask="url(#loan-b)"
              d="M193.502 161.059h107.063V140.88H193.502z"
            />
            <path
              pathLength="1"
              stroke="#102666"
              strokeWidth={5.005}
              strokeLinecap="round"
              strokeLinejoin="round"
              mask="url(#loan-b)"
              d="M193.502 161.059h107.063V140.88H193.502z"
            />
            <path
              pathLength="1"
              fill="#102666"
              mask="url(#loan-b)"
              d="M193.502 23.178h107.063V2.999H193.502z"
            />
            <path
              pathLength="1"
              stroke="#102666"
              strokeWidth={5.005}
              strokeLinecap="round"
              strokeLinejoin="round"
              mask="url(#loan-b)"
              d="M193.502 23.178h107.063V2.999H193.502zm9.529 117.702v-13.227a28.779 28.779 0 0112.04-23.41l31.061-22.215 30.491 22.204a28.782 28.782 0 0111.84 23.267v13.38H203.03z"
            />
            <path
              pathLength="1"
              d="M289.267 23.178v13.226a28.778 28.778 0 01-12.038 23.41L246.166 82.03l-30.491-22.203a28.785 28.785 0 01-11.84-23.266V23.178h85.432z"
              stroke="#102666"
              strokeWidth={5.005}
              strokeLinecap="round"
              strokeLinejoin="round"
              mask="url(#loan-b)"
            />
            <path
              pathLength="1"
              fill="#D74EA5"
              mask="url(#loan-b)"
              d="M223.241 140.948l23.792-29.494 23.791 29.494z"
            />
            <path
              pathLength="1"
              stroke="#102666"
              strokeWidth={5}
              strokeLinecap="round"
              strokeLinejoin="round"
              mask="url(#loan-b)"
              d="M223.241 140.948l23.792-29.494 23.791 29.494z"
            />
            <path
              pathLength="1"
              fill="#D74EA5"
              mask="url(#loan-b)"
              d="M285.507 50.607h-77.998l5.959 7.435 32.698 23.987 31.063-22.215z"
            />
            <path
              pathLength="1"
              stroke="#102666"
              strokeWidth={5}
              strokeLinecap="round"
              strokeLinejoin="round"
              mask="url(#loan-b)"
              d="M285.507 50.607h-77.998l5.959 7.435 32.698 23.987 31.063-22.215z"
            />
            <path
              pathLength="1"
              d="M198.76 161.319h-28.042a5.332 5.332 0 010-10.664h28.043a5.332 5.332 0 110 10.663"
              fill="#A5CB25"
              mask="url(#loan-b)"
            />
            <path
              pathLength="1"
              d="M198.76 161.319h-28.042a5.332 5.332 0 010-10.664h28.043a5.332 5.332 0 110 10.663z"
              stroke="#102666"
              strokeWidth={4.024}
              strokeLinecap="round"
              strokeLinejoin="round"
              mask="url(#loan-b)"
            />
            <path
              pathLength="1"
              d="M196.482 150.654H168.44a5.332 5.332 0 010-10.664h28.042a5.332 5.332 0 010 10.664"
              fill="#A5CB25"
              mask="url(#loan-b)"
            />
            <path
              pathLength="1"
              d="M196.482 150.654H168.44a5.332 5.332 0 010-10.664h28.042a5.332 5.332 0 010 10.664z"
              stroke="#102666"
              strokeWidth={4.024}
              strokeLinecap="round"
              strokeLinejoin="round"
              mask="url(#loan-b)"
            />
            <path
              pathLength="1"
              d="M198.76 139.99h-28.042a5.332 5.332 0 010-10.664h28.043a5.332 5.332 0 110 10.664"
              fill="#A5CB25"
              mask="url(#loan-b)"
            />
            <path
              pathLength="1"
              d="M198.76 139.99h-28.042a5.332 5.332 0 010-10.664h28.043a5.332 5.332 0 110 10.664z"
              stroke="#102666"
              strokeWidth={4.024}
              strokeLinecap="round"
              strokeLinejoin="round"
              mask="url(#loan-b)"
            />
            <path
              pathLength="1"
              d="M198.76 128.442h-28.042a5.332 5.332 0 010-10.664h28.043a5.332 5.332 0 110 10.664"
              fill="#A5CB25"
              mask="url(#loan-b)"
            />
            <path
              pathLength="1"
              d="M198.76 128.442h-28.042a5.332 5.332 0 010-10.664h28.043a5.332 5.332 0 110 10.664z"
              stroke="#102666"
              strokeWidth={4.024}
              strokeLinecap="round"
              strokeLinejoin="round"
              mask="url(#loan-b)"
            />
            <path
              pathLength="1"
              d="M198.76 116.893h-28.042a5.332 5.332 0 010-10.664h28.043a5.332 5.332 0 110 10.664"
              fill="#A5CB25"
              mask="url(#loan-b)"
            />
            <path
              pathLength="1"
              d="M198.76 116.893h-28.042a5.332 5.332 0 010-10.664h28.043a5.332 5.332 0 110 10.664z"
              stroke="#102666"
              strokeWidth={4.024}
              strokeLinecap="round"
              strokeLinejoin="round"
              mask="url(#loan-b)"
            />
            <path
              pathLength="1"
              d="M196.482 105.344H168.44a5.332 5.332 0 010-10.664h28.042a5.332 5.332 0 010 10.664"
              fill="#A5CB25"
              mask="url(#loan-b)"
            />
            <path
              pathLength="1"
              d="M196.482 105.344H168.44a5.332 5.332 0 010-10.664h28.042a5.332 5.332 0 010 10.664z"
              stroke="#102666"
              strokeWidth={4.024}
              strokeLinecap="round"
              strokeLinejoin="round"
              mask="url(#loan-b)"
            />
            <path
              pathLength="1"
              d="M198.76 93.796h-28.042a5.332 5.332 0 010-10.664h28.043a5.332 5.332 0 110 10.664"
              fill="#A5CB25"
              mask="url(#loan-b)"
            />
            <path
              pathLength="1"
              d="M198.76 93.796h-28.042a5.332 5.332 0 010-10.664h28.043a5.332 5.332 0 110 10.664z"
              stroke="#102666"
              strokeWidth={4.024}
              strokeLinecap="round"
              strokeLinejoin="round"
              mask="url(#loan-b)"
            />
            <path
              pathLength="1"
              d="M198.76 82.247h-28.042a5.332 5.332 0 010-10.664h28.043a5.332 5.332 0 110 10.664"
              fill="#A5CB25"
              mask="url(#loan-b)"
            />
            <path
              pathLength="1"
              d="M198.76 82.247h-28.042a5.332 5.332 0 010-10.664h28.043a5.332 5.332 0 110 10.664z"
              stroke="#102666"
              strokeWidth={4.024}
              strokeLinecap="round"
              strokeLinejoin="round"
              mask="url(#loan-b)"
            />
            <path
              pathLength="1"
              d="M196.482 70.698H168.44a5.332 5.332 0 010-10.664h28.042a5.332 5.332 0 010 10.664"
              fill="#A5CB25"
              mask="url(#loan-b)"
            />
            <path
              pathLength="1"
              d="M196.482 70.698H168.44a5.332 5.332 0 010-10.664h28.042a5.332 5.332 0 010 10.664z"
              stroke="#102666"
              strokeWidth={4.024}
              strokeLinecap="round"
              strokeLinejoin="round"
              mask="url(#loan-b)"
            />
            <path
              pathLength="1"
              d="M198.76 59.15h-28.042a5.332 5.332 0 010-10.664h28.043a5.332 5.332 0 110 10.664"
              fill="#A5CB25"
              mask="url(#loan-b)"
            />
            <path
              pathLength="1"
              d="M198.76 59.15h-28.042a5.332 5.332 0 010-10.664h28.043a5.332 5.332 0 110 10.664z"
              stroke="#102666"
              strokeWidth={4.024}
              strokeLinecap="round"
              strokeLinejoin="round"
              mask="url(#loan-b)"
            />
            <path
              pathLength="1"
              d="M198.76 47.601h-28.042a5.332 5.332 0 010-10.664h28.043a5.332 5.332 0 110 10.664"
              fill="#A5CB25"
              mask="url(#loan-b)"
            />
            <path
              pathLength="1"
              d="M198.76 47.601h-28.042a5.332 5.332 0 010-10.664h28.043a5.332 5.332 0 110 10.664z"
              stroke="#102666"
              strokeWidth={4.024}
              strokeLinecap="round"
              strokeLinejoin="round"
              mask="url(#loan-b)"
            />
            <path
              pathLength="1"
              d="M198.76 36.052h-28.042a5.332 5.332 0 010-10.664h28.043a5.332 5.332 0 110 10.664"
              fill="#A5CB25"
              mask="url(#loan-b)"
            />
            <path
              pathLength="1"
              d="M198.76 36.052h-28.042a5.332 5.332 0 010-10.664h28.043a5.332 5.332 0 110 10.664z"
              stroke="#102666"
              strokeWidth={4.024}
              strokeLinecap="round"
              strokeLinejoin="round"
              mask="url(#loan-b)"
            />
            <path
              pathLength="1"
              d="M231.608 150.654h-28.043a5.332 5.332 0 010-10.664h28.043a5.332 5.332 0 110 10.664"
              fill="#A5CB25"
              mask="url(#loan-b)"
            />
            <path
              pathLength="1"
              d="M231.608 150.654h-28.043a5.332 5.332 0 010-10.664h28.043a5.332 5.332 0 110 10.664z"
              stroke="#102666"
              strokeWidth={4.024}
              strokeLinecap="round"
              strokeLinejoin="round"
              mask="url(#loan-b)"
            />
            <path
              pathLength="1"
              d="M229.33 161.319h-28.043a5.332 5.332 0 010-10.664h28.043a5.332 5.332 0 010 10.663"
              fill="#A5CB25"
              mask="url(#loan-b)"
            />
            <path
              pathLength="1"
              d="M229.33 161.319h-28.043a5.332 5.332 0 010-10.664h28.043a5.332 5.332 0 010 10.663z"
              stroke="#102666"
              strokeWidth={4.024}
              strokeLinecap="round"
              strokeLinejoin="round"
              mask="url(#loan-b)"
            />
            <path
              pathLength="1"
              d="M224.773 139.99H196.73a5.332 5.332 0 010-10.664h28.043a5.332 5.332 0 010 10.664"
              fill="#A5CB25"
              mask="url(#loan-b)"
            />
            <path
              pathLength="1"
              d="M224.773 139.99H196.73a5.332 5.332 0 010-10.664h28.043a5.332 5.332 0 010 10.664z"
              stroke="#102666"
              strokeWidth={4.024}
              strokeLinecap="round"
              strokeLinejoin="round"
              mask="url(#loan-b)"
            />
            <path
              pathLength="1"
              d="M229.33 129.51h-28.043a5.332 5.332 0 010-10.664h28.043a5.332 5.332 0 010 10.664"
              fill="#A5CB25"
              mask="url(#loan-b)"
            />
            <path
              pathLength="1"
              d="M229.33 129.51h-28.043a5.332 5.332 0 010-10.664h28.043a5.332 5.332 0 010 10.664z"
              stroke="#102666"
              strokeWidth={4.024}
              strokeLinecap="round"
              strokeLinejoin="round"
              mask="url(#loan-b)"
            />
            <path
              pathLength="1"
              d="M233.887 118.846h-28.042a5.332 5.332 0 110-10.664h28.042a5.332 5.332 0 110 10.664"
              fill="#A5CB25"
              mask="url(#loan-b)"
            />
            <path
              pathLength="1"
              d="M233.887 118.846h-28.042a5.332 5.332 0 110-10.664h28.042a5.332 5.332 0 110 10.664z"
              stroke="#102666"
              strokeWidth={4.024}
              strokeLinecap="round"
              strokeLinejoin="round"
              mask="url(#loan-b)"
            />
            <path
              pathLength="1"
              d="M229.33 108.182h-28.043a5.332 5.332 0 010-10.664h28.043a5.332 5.332 0 010 10.664"
              fill="#A5CB25"
              mask="url(#loan-b)"
            />
            <path
              pathLength="1"
              d="M229.33 108.182h-28.043a5.332 5.332 0 010-10.664h28.043a5.332 5.332 0 010 10.664z"
              stroke="#102666"
              strokeWidth={4.024}
              strokeLinecap="round"
              strokeLinejoin="round"
              mask="url(#loan-b)"
            />
          </g>
        </g>
      </svg>
    </div>
  );
}

export default SvgComponent;
