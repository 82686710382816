import * as React from "react";
import styles from '../styles/modal-1-pie-3.module.scss'

function SvgComponent(props) {
  return (
    <div className={styles.svg}>
      <svg viewBox="0 0 166 166" {...props}>
        <g fill="none" fillRule="evenodd" stroke="#102666" strokeWidth={5}>
          <path
            fill="#D8D8D8"
            d="M80 0c44.183 0 80 35.817 80 80s-35.817 80-80 80S0 124.183 0 80 35.817 0 80 0zm0 37c-23.748 0-43 19.252-43 43s19.252 43 43 43 43-19.252 43-43-19.252-43-43-43z"
            transform="translate(3 3)"
          />
          <path
            fill="#DB8725"
            d="M6.369 48.668L40.376 63.27A42.865 42.865 0 0037 80a42.836 42.836 0 004.032 18.204L7.262 113.35C2.6 103.2 0 91.903 0 80c0-11.12 2.269-21.71 6.369-31.332z"
            transform="translate(3 3)"
          />
        </g>
      </svg>
    </div>
  );
}

export default SvgComponent;
