import React from "react"
import Layout from '../components/layout.js'
import Hero from "../components/hero/hero.js"
import Purpose from "../components/purpose/index.js"
import Patience from "../components/patience/index.js"
import Results from "../components/results/index.js"
import Future from "../components/future/index.js"

const Home = () => {
  return (
    <Layout>
      <Hero/>
      <Purpose/>
      <Patience/>
      <Results/>
      <Future/>
    </Layout>
  )
}

export default Home
