import * as React from "react";
import styles from '../styles/turnover.module.scss'

function SvgComponent(props) {
  return (
    <div className={styles.svg}>
      <svg viewBox="0 0 147 167" {...props} className="animate animate--draw">
        <g
          transform="translate(-92 -1158) translate(94 1161) translate(.88 .31)"
          stroke="none"
          strokeWidth={1}
          fill="none"
          fillRule="evenodd"
        >
          <path
            pathLength="1"
            d="M118.228 80.316c0 26.223-21.26 47.481-47.481 47.481-26.224 0-47.483-21.258-47.483-47.481 0-26.223 21.259-47.481 47.483-47.481 26.222 0 47.48 21.258 47.48 47.481"
            fill="#A5CB25"
          />
          <path
            pathLength="1"
            d="M118.228 80.316c0 26.223-21.26 47.481-47.481 47.481-26.224 0-47.483-21.258-47.483-47.481 0-26.223 21.259-47.481 47.483-47.481 26.222 0 47.48 21.258 47.48 47.481z"
            stroke="#102666"
            strokeWidth={5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            pathLength="1"
            d="M51.136 148.309C21.604 139.807 0 112.584 0 80.316 0 41.245 31.674 9.57 70.746 9.57M89.503 12.084c29.97 8.22 51.988 35.654 51.988 68.231 0 39.073-31.674 70.748-70.745 70.748"
            stroke="#102666"
            strokeWidth={5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            pathLength="1"
            stroke="#102666"
            strokeWidth={5}
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M62.7697 -5.68434189e-14L72.3387 9.57 62.7697 19.14"
          />
          <path
            pathLength="1"
            stroke="#102666"
            strokeWidth={5}
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M77.3899 160.6319L67.8209 151.0619 77.3899 141.4919"
          />
          <path
            pathLength="1"
            d="M81.374 64.144c-1.435-1.27-3.493-1.47-5.403-1.61-2.663-.195-5.559-.332-7.765 1.173-2.81 1.915-3.456 5.74-3.586 9.138-.327 8.532.988 17.42-2.127 25.37h18.378"
            stroke="#102666"
            strokeWidth={5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            pathLength="1"
            stroke="#102666"
            strokeWidth={5}
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M56.7785 81.8057L76.2635 81.8057"
          />
        </g>
      </svg>
    </div>
  );
}

export default SvgComponent;
