import * as React from "react";

function Percent(props) {
  return (
    <div className="percent">
      <svg viewBox="0 0 75 75" {...props} className="animate animate--draw">
        <g
          fill="none"
          fillRule="evenodd"
          stroke="#102666"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={5}
          transform="translate(3.058 2.863)"
        >
          <path pathLength="1" d="M66.214 4.366L2.172 68.409" />
          <path pathLength="1" d="M21.198 10.599c0 5.854-4.745 10.599-10.6 10.599C4.745 21.198 0 16.453 0 10.599S4.745 0 10.598 0c5.855 0 10.6 4.745 10.6 10.599zm47.947 47.947c0 5.854-4.746 10.6-10.6 10.6-5.853 0-10.598-4.746-10.598-10.6 0-5.854 4.745-10.599 10.598-10.599 5.854 0 10.6 4.745 10.6 10.6z" />
        </g>
      </svg>
    </div>
  );
}

export default Percent;
