import * as React from "react";

function SvgComponent(props) {
  return (
    <div className="search">
      <svg viewBox="0 0 26 26" {...props}>
        <path
          d="M10 21c-3.86 0-7-3.14-7-7s3.14-7 7-7 7 3.14 7 7-3.14 7-7 7zm14 4.486l-6.078-6.081a9.813 9.813 0 001.73-5.573C19.653 8.4 15.257 4 9.827 4 4.396 4 0 8.4 0 13.832c0 5.433 4.397 9.832 9.826 9.832a9.8 9.8 0 005.57-1.732L21.474 28 24 25.486z"
          transform="translate(-121 -3227) translate(122 3224)"
          fill="#102666"
          stroke="#102666"
          strokeWidth={1}
          fillRule="evenodd"
        />
      </svg>
    </div>
  );
}

export default SvgComponent;
