import * as React from "react";
import styles from '../styles/cog.module.scss'

function SvgComponent(props) {
  return (
    <div className={styles.svg}>
      <svg viewBox="0 0 121 121" {...props}>
        <g
          stroke="#102666"
          strokeWidth={5}
          fill="none"
          fillRule="evenodd"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path
            d="M73.41 73.41c-8.788 8.787-23.033 8.787-31.82 0-8.786-8.787-8.786-23.033 0-31.82 8.787-8.787 23.032-8.787 31.82 0 8.787 8.787 8.787 23.033 0 31.82z"
            transform="translate(-710 -125) translate(13 10) translate(700 118)"
          />
          <path
            d="M115 67.342V47.658l-15.554-2.415a43.372 43.372 0 00-3.616-8.74l9.288-12.702-13.92-13.918-12.7 9.289a43.373 43.373 0 00-8.74-3.617L67.343 0H47.657l-2.415 15.555a43.362 43.362 0 00-8.738 3.617L23.801 9.883 9.881 23.801l9.29 12.703a43.444 43.444 0 00-3.616 8.739L0 47.658v19.684l15.555 2.415a43.413 43.413 0 003.615 8.74L9.882 91.198l13.92 13.92 12.702-9.29a43.434 43.434 0 008.738 3.616L47.657 115h19.686l2.415-15.555a43.477 43.477 0 008.74-3.615l12.7 9.288 13.92-13.92-9.288-12.702a43.34 43.34 0 003.616-8.739L115 67.342z"
            transform="translate(-710 -125) translate(13 10) translate(700 118)"
          />
        </g>
      </svg>
    </div>
  );
}

export default SvgComponent;
