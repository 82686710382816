import * as React from "react";
import styles from '../styles/employ.module.scss';

function SvgComponent(props) {
  return (
    <div className={styles.svg}>
      <svg preserveAspectRatio="xMinYMin slice" viewBox="0 0 336 349" {...props} className="animate animate--draw">
        <defs>
          <path
            pathLength="1"
            id="employ-a"
            d="M0 348L335.383834 348 335.383834 0.366945559 0 0.366945559z"
          />
        </defs>
        <g
          transform="translate(-54 -4145) translate(54 4145)"
          stroke="none"
          strokeWidth={1}
          fill="none"
          fillRule="evenodd"
        >
          <path
            pathLength="1"
            fill="#DB8725"
            d="M105 222.48129L233 222.48129 233 135.48129 105 135.48129z"
          />
          <path
            pathLength="1"
            stroke="#102666"
            strokeWidth={5}
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M105 222.48129L233 222.48129 233 135.48129 105 135.48129z"
          />
          <path
            pathLength="1"
            stroke="#102666"
            strokeWidth={5}
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M137 135.48129L200 135.48129 200 118.48129 137 118.48129z"
          />
          <g transform="translate(0 .481)">
            <path
              pathLength="1"
              d="M232.024 168.681l-16.682 2.298a346.647 346.647 0 01-94.65 0l-16.683-2.298V135.47h128.015v33.211z"
              fill="#DB8725"
            />
            <path
              pathLength="1"
              d="M232.024 168.681l-16.682 2.298a346.647 346.647 0 01-94.65 0l-16.683-2.298V135.47h128.015v33.211z"
              stroke="#102666"
              strokeWidth={5}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          <mask id="employ-b" fill="#fff">
              <use xlinkHref="#employ-a" />
            </mask>
            <path
              pathLength="1"
              fill="#FFF"
              mask="url(#employ-b)"
              d="M129.849543 184.084023L141.750125 184.084023 141.750125 163.108298 129.849543 163.108298z"
            />
            <path
              pathLength="1"
              stroke="#102666"
              strokeWidth={5}
              strokeLinecap="round"
              strokeLinejoin="round"
              mask="url(#employ-b)"
              d="M129.849543 184.084023L141.750125 184.084023 141.750125 163.108298 129.849543 163.108298z"
            />
            <path
              pathLength="1"
              d="M139.71 274.827c-43.6-12.553-75.493-52.747-75.493-100.39 0-57.688 46.762-104.454 104.444-104.454M196.353 73.695c44.242 12.136 76.75 52.642 76.75 100.742 0 57.688-46.76 104.454-104.442 104.454"
              stroke="#102666"
              strokeWidth={5}
              strokeLinecap="round"
              strokeLinejoin="round"
              mask="url(#employ-b)"
            />
            <path
              pathLength="1"
              d="M304.015 201.064c-12.404 63.439-68.29 111.31-135.354 111.31-76.173 0-137.923-61.756-137.923-137.936M33.361 147.529C45.877 84.229 101.695 36.5 168.661 36.5c76.172 0 137.922 61.756 137.922 137.936"
              stroke="#102666"
              strokeWidth={5}
              strokeLinecap="round"
              strokeLinejoin="round"
              mask="url(#employ-b)"
            />
            <path
              pathLength="1"
              stroke="#102666"
              strokeWidth={5}
              strokeLinecap="round"
              strokeLinejoin="round"
              mask="url(#employ-b)"
              d="M156.885379 55.8539003L171.013332 69.9832986 156.885379 84.1126968"
            />
            <path
              pathLength="1"
              stroke="#102666"
              strokeWidth={5}
              strokeLinecap="round"
              strokeLinejoin="round"
              mask="url(#employ-b)"
              d="M178.46964 293.020886L164.341688 278.891488 178.46964 264.762089"
            />
            <path
              pathLength="1"
              stroke="#102666"
              strokeWidth={5}
              strokeLinecap="round"
              strokeLinejoin="round"
              mask="url(#employ-b)"
              d="M16.6105638 184.17217L30.7385163 170.042771 44.8664688 184.17217"
            />
            <path
              pathLength="1"
              stroke="#102666"
              strokeWidth={5}
              strokeLinecap="round"
              strokeLinejoin="round"
              mask="url(#employ-b)"
              d="M320.711302 164.159079L306.58335 178.288477 292.4544 164.159079"
            />
            <path
              pathLength="1"
              stroke="#102666"
              strokeWidth={5}
              strokeLinecap="round"
              strokeLinejoin="round"
              mask="url(#employ-b)"
              d="M332.854861 261.737979L313.490493 266.66183 308.568142 247.295481"
            />
            <path
              pathLength="1"
              stroke="#102666"
              strokeWidth={5}
              strokeLinecap="round"
              strokeLinejoin="round"
              mask="url(#employ-b)"
              d="M4.46600831 86.0810407L23.8303763 81.1571897 28.7537234 100.523539"
            />
            <path
              pathLength="1"
              fill="#FFF"
              mask="url(#employ-b)"
              d="M193.831122 184.084023L205.731703 184.084023 205.731703 163.108298 193.831122 163.108298z"
            />
            <path
              pathLength="1"
              stroke="#102666"
              strokeWidth={5}
              strokeLinecap="round"
              strokeLinejoin="round"
              mask="url(#employ-b)"
              d="M193.831122 184.084023L205.731703 184.084023 205.731703 163.108298 193.831122 163.108298z"
            />
            <path
              pathLength="1"
              d="M332.339 224.331a170.507 170.507 0 01-19.177 41.638M41.12 59.614C72.476 24.77 117.924 2.86 168.489 2.86c77.837 0 143.55 51.92 164.402 123.02M2.96 129.898a170.383 170.383 0 0121.017-47.754M297.13 287.324c-31.394 35.673-77.39 58.183-128.641 58.183-79.944 0-147.097-54.766-165.997-128.83"
              stroke="#102666"
              strokeWidth={5}
              strokeLinecap="round"
              strokeLinejoin="round"
              mask="url(#employ-b)"
            />
          </g>
        </g>
      </svg>
    </div>
  );
}

export default SvgComponent;
