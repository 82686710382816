import * as React from "react";

function SvgComponent(props) {
  return (
    <div className="close">
      <svg viewBox="0 0 36 36" {...props}>
        <g
          transform="translate(-1281 -32) translate(13 10) rotate(-45 677.142 -1498.197)"
          stroke="#102666"
          strokeWidth={5}
          fill="none"
          fillRule="evenodd"
          strokeLinecap="square"
        >
          <path d="M19.5 0L19.5 40" />
          <path d="M40 20.5L-6.82232049e-13 20.5" />
        </g>
      </svg>
    </div>
  );
}

export default SvgComponent;
