import React from 'react';
import Arrow from './svgs/arrow.js'
import Turnover from './svgs/turnover.js'
import Employees from './svgs/employees.js'
import Assets from './svgs/assets.js'
import Cash from './svgs/cash.js'
import Profit from './svgs/profit.js'
import Liabilities from './svgs/liabilities.js'
import Piechart from './svgs/piechart.js'
import Breakdown1 from './svgs/breakdown-1.js'
import Breakdown2 from './svgs/breakdown-2.js'
import Breakdown3 from './svgs/breakdown-3.js'
import Breakdown4 from './svgs/breakdown-4.js'
import Breakdown5 from './svgs/breakdown-5.js'
import RightDownArrow from './svgs/right-down-arrow.js'

export default function Purpose(props) {
  return (
    <section className="section" id="purpose">
      <div className="animate animate--section">
        <div className="section__tab">
          <h3 className="h-m h-w-b">Purpose</h3>
        </div>
        <div className="blocks blocks--tab">
          <div className="block block-l-6w blocks">
            <div className="block block--text pad pad--s animate animate--fb">
              <h2 className="h-xl">Who did the fund help?</h2>
            </div>
            <div className="block block-9w block--text pad pad--s pad--m animate animate--fl">
              <p className="h-w-b">This is your average loan recipient:</p>
            </div>
            <div className="block block-3w icon pad animate animate--ft animate--check-safari">
              <Arrow/>
            </div>
          </div>
          <div className="block block-l-6w block--text pad pad--s animate animate--fr">
            <p>Futurebuilders provided a means for social sector organisations to access flexible, long-term investment products that are not readily available from commercial banks or private investors.</p>
          </div>
          <div className="block blocks">
            <div className="block icon order-1 order-s-1 order-xl-d block-s-4w block-xl-2w pad animate animate--ft animate--check-safari">
              <Turnover/>
            </div>
            <div className="block icon order-3 order-s-2 order-xl-d block-s-4w block-xl-2w pad animate animate--fb animate--check-safari">
              <Employees/>
            </div>
            <div className="block icon order-5 order-s-3 order-xl-d block-s-4w block-xl-2w pad animate animate--fl animate--check-safari">
              <Assets/>
            </div>
            <div className="block icon order-7 order-s-7 order-xl-d block-s-4w block-xl-2w pad animate animate--fr animate--check-safari">
              <Cash/>
            </div>
            <div className="block icon order-9 order-s-8 order-xl-d block-s-4w block-xl-2w pad animate animate--ft animate--check-safari">
              <Profit/>
            </div>
            <div className="block icon order-11 order-s-9 order-xl-d block-s-4w block-xl-2w pad animate animate--fb animate--check-safari">
              <Liabilities/>
            </div>

            <div className="block order-2 order-s-4 order-xl-d block-s-4w block-xl-2w block--stat pad animate animate--fb">
              <p>Turnover:</p>
              <h2 className="block__number">£712k</h2>
            </div>
            <div className="block order-4 order-s-5 order-xl-d block-s-4w block-xl-2w block--stat pad animate animate--ft">
              <p>Employees:</p>
              <h2 className="block__number">30</h2>
            </div>
            <div className="block order-6 order-s-6 order-xl-d block-s-4w block-xl-2w block--stat pad animate animate--fr">
              <p>Total assets:</p>
              <h2 className="block__number">£498k</h2>
            </div>
            <div className="block order-8 order-s-10 order-xl-d block-s-4w block-xl-2w block--stat pad animate animate--fl">
              <p>Cash:</p>
              <h2 className="block__number">£110k</h2>
            </div>
            <div className="block order-10 order-s-11 order-xl-d block-s-4w block-xl-2w block--stat pad animate animate--fb">
              <p>Profit:</p>
              <h2 className="block__number">£14.5k</h2>
            </div>
            <div className="block order-12 order-s-12 order-xl-d block-s-4w block-xl-2w block--stat pad animate animate--ft">
              <p>Liabilities:</p>
              <h2 className="block__number">£133k</h2>
            </div>
          </div>
          <div className="block block--orange animate animate--fb"></div>
          <div className="block block-l-6w block--text pad pad--s animate animate--ft">
            <h2 className="h-xl">How did the fund work?</h2>
          </div>
          <div className="block block-l-6w blocks">
            <div className="block block-9w block--text pad pad--s animate animate--fb">
              <h3 className="h-m h-w-b">The fund breakdown</h3>
            </div>
            <div className="block block-3w icon pad animate animate--fr animate--check-safari">
              <Arrow/>
            </div>
          </div>
          <div className="block block-l-6w icon pad pad--s animate animate--fb">
            <Piechart/>
          </div>
          <div className="block block-l-6w block-l-3h combo animate animate--fl pad">
            <Breakdown1/>
            <div className="combo__text">
              <h3 className="h-m h-w-b">£17m</h3>
              <p>27 loan-only deals</p>
              <p>26 unique organisations</p>
            </div>
          </div>

          <div className="block block-l-6w block-l-3h combo animate animate--fr pad">
            <Breakdown2/>
            <div className="combo__text">
              <h3 className="h-m h-w-b">£4.6m</h3>
              <p>189 grant-only deals</p>
              <p>183 unique organisations</p>
            </div>
          </div>

          <div className="block block-l-6w block-l-3h combo animate animate--ft pad">
            <Breakdown3/>
            <div className="combo__text">
              <h3 className="h-m h-w-b h-relative">
                <span>£121.6m</span>
                <RightDownArrow/>
              </h3>
              <p>179 blended deals</p>
              <p>175 unique organisations</p>
            </div>
          </div>

          <div className="block block-l-3w block-l-3h combo animate animate--fb pad">
            <Breakdown4/>
            <div className="combo__text">
              <h3 className="h-m h-w-b">£101m</h3>
              <p className="text text--s">Loan components</p>
            </div>
          </div>

          <div className="block block-l-3w block-l-3h combo animate animate--fr pad">
            <Breakdown5/>
            <div className="combo__text">
              <h3 className="h-m h-w-b">£20.6m</h3>
              <p className="text text--s">Grant components</p>
            </div>
          </div>
        </div>
        <a href="#page" onClick={props.scrollPage} className="section__tab section__tab--bottom">
          <h3 className="h-m h-w-b">
            Back to top
          </h3>
        </a>
      </div>
    </section>
  )
}
