import * as React from "react";
import styles from '../styles/risk.module.scss';

function SvgComponent(props) {
  return (
    <div className={styles.svg}>
      <svg viewBox="0 0 330 322" {...props} className="animate animate--draw">
        <defs>
          <path
            pathLength="1" id="risk-a" d="M0 325L336 325 336 0.413 0 0.413z" />
        </defs>
        <g
          transform="translate(-2.5 -.5)"
          stroke="none"
          strokeWidth={1}
          fill="none"
          fillRule="evenodd"
        >
          <path
            pathLength="1"
            fill="#DB8725"
            d="M167.5823 96.512L77.5243 222.811 258.4763 222.211z"
          />
          <path
            pathLength="1"
            stroke="#102666"
            strokeWidth={5}
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M167.5823 96.512L77.5243 222.811 258.4763 222.211z"
          />
          <path
            pathLength="1"
            stroke="#102666"
            strokeWidth={5}
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M167.7434 144.9616L167.8834 187.0866"
          />
          <g transform="translate(0 .587)">
            <path
              pathLength="1"
              d="M172.307 202.112a4.371 4.371 0 11-8.745.03 4.371 4.371 0 018.745-.03"
              fill="#102666"
            />
            <path
              pathLength="1"
              d="M172.307 202.112a4.371 4.371 0 11-8.745.03 4.371 4.371 0 018.745-.03z"
              stroke="#102666"
              strokeWidth={5}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              pathLength="1"
              stroke="#102666"
              strokeWidth={5.005}
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M67.1038 72.4671L101.1788 131.4871"
            />
            <path
              pathLength="1"
              stroke="#102666"
              strokeWidth={5.005}
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M109.3 106.2764L102.227 132.6744 75.828 125.6014"
            />
            <path
              pathLength="1"
              stroke="#102666"
              strokeWidth={5.005}
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M267.595 72.4671L233.52 131.4871"
            />
            <path
              pathLength="1"
              stroke="#102666"
              strokeWidth={5.005}
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M225.3987 106.2764L232.4717 132.6744 258.8697 125.6014"
            />
            <path
              pathLength="1"
              stroke="#102666"
              strokeWidth={5.005}
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M166.8948 2.9155L166.8948 71.0665"
            />
            <path
              pathLength="1"
              stroke="#102666"
              strokeWidth={5.005}
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M186.5334 53.2933L167.2084 72.6183 147.8844 53.2933"
            />
          <mask id="risk-b" fill="#fff">
              <use xlinkHref="#risk-a" />
            </mask>
            <path
              pathLength="1"
              fill="#A5CB25"
              mask="url(#risk-b)"
              d="M2.5 322.5L333.5 322.5 333.5 278.153 2.5 278.153z"
            />
            <path
              pathLength="1"
              stroke="#102666"
              strokeWidth={5}
              strokeLinecap="round"
              strokeLinejoin="round"
              mask="url(#risk-b)"
              d="M149.9348 276.1665L186.5338 271.3125 149.9348 266.4635 186.5338 261.6115 149.9348 256.7565 186.5338 251.9025 149.9348 247.0485 186.5338 242.1935 149.9348 237.3405 186.5338 232.4835 149.9348 227.6235 186.5338 222.7625"
            />
          </g>
          <path
            pathLength="1" stroke="#102666" strokeWidth={5} d="M333 279.5L2 279.5" />
        </g>
      </svg>
    </div>
  );
}

export default SvgComponent;
