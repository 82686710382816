import React from 'react'
import Close from '../../svgs/close.js'
import Cog from '../svgs/modal-cog.js'
import Pie1 from '../svgs/modal-1-pie-1.js'
import Pie2 from '../svgs/modal-1-pie-2.js'
import Pie3 from '../svgs/modal-1-pie-3.js'
import Pie4 from '../svgs/modal-1-pie-4.js'

const Modal = (props) => {
  return (
    <div className={`modal ${props.activeModal === 'patience-modal-1' ? 'modal-is-active' : ''}`}>
      <div className="modal__container">
        <div className="modal__content">
          <div className="blocks">
            <div className="row row--green row--j-e">
              <button className="modal__close" onClick={props.toggleModal}>
                <span className="visually-hidden">Close modal</span>
                <Close/>
              </button>
            </div>
            <div className="block block-l-6w block--text pad pad--s pad--m">
              <Pie1/>
              <p><strong>114</strong> investees out of <strong>247</strong> (46%) of FBE loan investees were provided with 1 or more financial variations. These include the 37 that moved from 6% fixed to 3% variable interest rates (option provided in the contracts at point of offer).</p>
            </div>
            <div className="block block-l-6w blocks">
              <div className="block block-3w icon pad">
                <Cog/>
              </div>
              <div className="block block-6w block--text pad pad--s">
                <p>Of these</p>
              </div>
              <div className="block block-3w block--navy"></div>
              <div className="block combo combo--j-sb pad pad--s">
                <div className="combo__text combo__text--left">
                  <p><strong>68.4%</strong></p>
                  <p>were interest rates</p>
                </div>
                <Pie2/>
              </div>
              <div className="block combo combo--j-sb pad pad--s">
                <div className="combo__text combo__text--left">
                  <p><strong>13.2%</strong></p>
                  <p>were both interest and payment variations</p>
                </div>
                <Pie3/>
              </div>
              <div className="block combo combo--j-sb pad pad--s">
                <div className="combo__text combo__text--left">
                  <p><strong>18.4%</strong></p>
                  <p>were payment changes</p>
                </div>
                <Pie4/>
              </div>
            </div>
            <div className="row row--green"></div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Modal
