import React from 'react'
import Loan from './svgs/loan.js'
import Flexibility from './svgs/flexibility.js'
import Invest from './svgs/invest.js'
import Search from '../svgs/search.js'
import Modal1 from './modals/modal-1.js'
import Modal2 from './modals/modal-2.js'

const Patience = (props) => {
  return (
    <section className="section" id="patience">
      <div className="animate animate--section">
        <div className="section__tab">
          <h3 className="h-m h-w-b">Patience</h3>
        </div>
        <div className="blocks blocks--tab">
          <div className="block block-s-6w block--text pad pad--s animate animate--fb">
            <h2 className="h-xl">What makes social investment work?</h2>
          </div>
          <div className="block block-s-6w block--text pad pad--s pad--m animate animate--ft">
            <p>Futurebuilders demonstrated how patience and flexibility in investment can effectively support the social economy at scale, while providing modest financial returns for areas most in need.</p>
          </div>
          <div className="block block-s-3w icon icon--bottom pad-top pad-top--s animate animate--fl animate--check-safari">
            <Loan/>
          </div>
          <div className="block block-s-9w block--text block--text-l pad pad--s pad--m animate animate--fr">
            <h3 className="h-l h-w-m">Patience</h3>
            <p>The average loan length was <strong>13.9 years</strong>, with longer loan terms corresponding to higher returns.</p>
          </div>
          <div className="block block-s-9w block--text block--text-l pad pad--s pad--m animate animate--fb">
            <h3 className="h-l h-w-m">Flexibility</h3>
            <p>Financial and non-financial variations were applied to a significant number of investments, representing the long-term commitment to supporting investees through difficult times. A variation could be a repayment holiday, or a change in loan purpose.</p>
            <button className="btn" onClick={props.toggleModal} data-modal="patience-modal-1">
              <Search/>
              <span className="h-w-m">Show graph</span>
            </button>
          </div>
          <div className="block block-s-3w icon icon--bottom animate animate--ft animate--check-safari">
            <Flexibility/>
          </div>
          <div className="block block-s-3w icon icon--bottom pad-top pad-top--s animate animate--fl animate--check-safari">
            <Invest/>
          </div>
          <div className="block block-s-9w block--text block--text-l pad pad--s pad--m animate animate--fr">
            <h3 className="h-l h-w-m">Investing where most in need</h3>
            <p>Over 40% of investment was disbursed to the 20% most deprived areas in the country, ensuring social impact objectives remain at the heart of social investment decision-making.</p>
            <button className="btn" onClick={props.toggleModal} data-modal="patience-modal-2">
              <Search/>
              <span className="h-w-m">Show graph</span>
            </button>
          </div>
          <div className="block block--green animate animate--fb"></div>
        </div>
        <a href="#page" onClick={props.scrollPage} className="section__tab section__tab--bottom">
          <h3 className="h-m h-w-b">
            Back to top
          </h3>
        </a>
      </div>

      <Modal1
        activeModal={props.activeModal}
        toggleModal={props.toggleModal}
      />

      <Modal2
        activeModal={props.activeModal}
        toggleModal={props.toggleModal}
      />
    </section>
  )
}

export default Patience
