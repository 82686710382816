import * as React from "react";
import styles from '../styles/subsidy.module.scss';

function SvgComponent(props) {
  return (
    <div className={styles.svg}>
      <svg viewBox="0 0 324 279" {...props} className="animate animate--draw">
        <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
          <g transform="translate(-1 -2) rotate(23 103.967 512.355)">
            <path
              pathLength="1"
              d="M69.718 34.994c0 19.178-15.546 34.725-34.724 34.725C15.816 69.719.269 54.172.269 34.994S15.816.269 34.994.269s34.724 15.547 34.724 34.725"
              fill="#A5CB25"
            />
          <path
            pathLength="1"
              d="M69.718 34.994c0 19.178-15.546 34.725-34.724 34.725C15.816 69.719.269 54.172.269 34.994S15.816.269 34.994.269s34.724 15.547 34.724 34.725z"
              stroke="#102666"
              strokeWidth={5}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          <path
            pathLength="1"
              d="M42.767 23.166c-1.05-.927-2.555-1.074-3.952-1.176-1.948-.143-4.065-.244-5.679.857-2.054 1.4-2.527 4.198-2.623 6.682-.238 6.24.724 12.74-1.555 18.555h13.44"
              stroke="#102666"
              strokeWidth={5}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          <path
            pathLength="1"
              stroke="#102666"
              strokeWidth={5}
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M24.7792462 36.0833308L39.0296308 36.0833308"
            />
          </g>
          <g
            transform="translate(-1 -2) rotate(36 -227.283 517.633)"
            stroke="#102666"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={5}
          >
            <path
              pathLength="1" d="M0.000500000001 3.87245791e-13L32.4775 32.477" />
            <path
              pathLength="1" d="M32.477 3.87245791e-13L-5.22248911e-13 32.477" />
          </g>
          <path
            pathLength="1"
            transform="translate(-1 -2) rotate(112 -43.93 148.153)"
            stroke="#102666"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={5}
            d="M0.000500000001 3.87245791e-13L32.4775 32.477"
          />
          <g
            stroke="#102666"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={5}
          >
            <path
              pathLength="1"
              d="M0 0c6.138 3.71 16.458 10.177 22.908 12.764C48.707 24.4 93.422 40.304 101.823 45.18c-14.962 22.893-24.17 48.825-34.708 73.903-3.01 7.156-6.13 14.266-9.532 21.243-18.175-9.166-38.93-19.625-57.104-28.79"
              fill="#0079B9"
              transform="translate(-1 -2) translate(0 48)"
            />
          <path
            pathLength="1"
              d="M92.765 60.888c21.248 2.984 42.103-1.856 63.044 2.831"
              transform="translate(-1 -2) translate(0 48)"
            />
          <path
            pathLength="1"
              d="M240.914 144.671c-24.51-18.104-45.149-38.796-70.949-54.315-9.03 9.053-19.349 18.106-33.54 20.692-5.16 0-11.61-3.88-9.03-9.053 2.58-3.88 5.16-9.053 9.03-12.932 5.16-7.759 6.45-18.104 15.48-23.278 21.93-11.639 43.86-2.586 67.08 2.587 10.32 1.292 19.349-1.293 29.669-3.88l11.61-3.88c.378-.126.744-.265 1.099-.413 3.326-1.389 6.42-2.758 9.773-4.075 10.199-4.005 20-8.947 29.783-13.882 9.248-4.664 18.523-9.345 27.116-15.131L330 123c-13.333 3.333-27.667 6.333-43 9-23 4-30 4-43 14"
              fill="#D74EA5"
              transform="translate(-1 -2) translate(0 48)"
            />
          <path
            pathLength="1"
              d="M162.25 136.096a344.499 344.499 0 0036.975 34.598c3.397 2.74 6.912 5.452 10.981 7.021 4.067 1.572 8.827 1.873 12.681-.168 3.857-2.041 6.45-7.387 5.113-11.547"
              transform="translate(-1 -2) translate(0 48)"
            />
          <path
            pathLength="1"
              d="M111.773 139.027c.148-4.724-4.554-8.61-9.268-8.737-4.713-.126-9.09 2.689-12.166 6.27-2.19 2.552-3.916 5.653-4.144 9.012-.227 3.358 1.307 6.96 4.28 8.522 1.183.622 2.509.898 3.816 1.166l3.366.692"
              fill="#D74EA5"
              transform="translate(-1 -2) translate(0 48)"
            />
          <path
            pathLength="1"
              d="M184 125a344.499 344.499 0 0036.975 34.598c3.397 2.74 6.911 5.452 10.98 7.021 4.068 1.572 8.827 1.873 12.682-.168 3.856-2.041 6.393-6.794 5.055-10.954-1.128-2.998-5.025-7.164-11.692-12.497"
              transform="translate(-1 -2) translate(0 48)"
            />
          <path
            pathLength="1"
              d="M100.553 152.828c-1.34 2.327-2.438 4.858-2.662 7.536-.223 2.677.525 5.528 2.438 7.41 3.076 3.023 7.985 2.767 12.266 2.285L128 149c-2.667-12-7.667-15-15-9s-11.482 10.276-12.447 12.828z"
              fill="#D74EA5"
              transform="translate(-1 -2) translate(0 48)"
            />
          <path
            pathLength="1"
              d="M127.623 145.6c-.65-3.84-3.636-7.183-7.37-8.254-3.736-1.071-6.933.184-9.512 3.097-3.423 3.87-6.865 8.126-10.017 12.224"
              transform="translate(-1 -2) translate(0 48)"
            />
          <path
            pathLength="1"
              d="M140.217 168.913c-2.597 4.404-5.332 8.957-9.572 11.804-4.243 2.845-10.453 3.437-14.182-.056-2.97-2.782-3.59-7.405-2.59-11.355 1-3.948 3.355-7.393 5.666-10.745l3.937-5.71c1.581-2.293 3.256-4.672 5.71-5.985 3.273-1.749 7.41-1.22 10.651.583 2.617 1.456 7.858 5.474 7.82 8.723-.04 3.322-5.72 9.825-7.44 12.741zM134.078 179.791c.673 3.7 1.86 7.98 5.363 9.326 1.12.43 2.343.487 3.542.522 2.034.061 4.097.075 6.069-.428 3.896-.993 7.026-3.894 9.56-7.024a46.39 46.39 0 007.393-12.94L150 156l-15.922 23.791z"
              fill="#D74EA5"
              transform="translate(-1 -2) translate(0 48)"
            />
          <path
            pathLength="1"
              d="M146.968 153.176a196.473 196.473 0 0123.893 21.356c3.727 3.923 7.339 8.042 11.823 11.067 4.482 3.025 10.062 4.873 15.328 3.662 5.268-1.21 9.845-6.123 9.45-11.525M156.273 186.054c.876 2.794 3.25 4.807 5.51 6.659 1.293 1.06 2.638 2.144 4.248 2.585 1.567.43 3.23.204 4.83-.079 2.653-.468 5.317-1.097 7.688-2.383 2.368-1.286 4.437-3.302 5.31-5.856M63 129a110.644 110.644 0 0123 11"
              transform="translate(-1 -2) translate(0 48)"
            />
          </g>
          <g
            transform="translate(-1 -2) rotate(-8 107.752 -682.883)"
            stroke="#102666"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={5}
          >
            <path
              pathLength="1" d="M42.4253146 2.51865606L1.42531463 43.5186561" />
            <path
              pathLength="1" d="M14.125 7.161a7 7 0 11-14 0 7 7 0 0114 0zM44.084 37.942a7 7 0 11-14 0 7 7 0 0114 0z" />
          </g>
        </g>
      </svg>
    </div>
  );
}

export default SvgComponent;
