import * as React from "react";
import styles from '../styles/compass.module.scss';

function Compass(props) {
  return (
    <div className={styles.svg}>
      <svg viewBox="0 0 98 98" {...props} className="animate animate--draw">
        <g
          fill="none"
          fillRule="evenodd"
          stroke="#102666"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={5}
          transform="translate(3.085 3.086)"
        >
          <path pathLength="1" d="M92.33 46.165c0 25.496-20.669 46.165-46.165 46.165S0 71.661 0 46.165C0 20.668 20.669 0 46.165 0S92.33 20.668 92.33 46.165z" />
          <path pathLength="1" d="M64.888 27.443L40.292 40.292 27.444 64.887 52.039 52.038z" />
          <path pathLength="1" d="M45.612 45.897L45.797 46.266" />
          <path pathLength="1" d="M46.165 10.971L46.165 19.688" />
          <path pathLength="1" d="M21.279 21.279L27.443 27.443" />
          <path pathLength="1" d="M10.971 46.165L19.689 46.165" />
          <path pathLength="1" d="M10.97 46.165c0-19.437 15.759-35.194 35.195-35.194 19.438 0 35.195 15.757 35.195 35.194 0 19.437-15.757 35.194-35.195 35.194-19.436 0-35.194-15.757-35.194-35.194z" />
          <path pathLength="1" d="M46.165 81.359L46.165 72.642" />
          <path pathLength="1" d="M71.052 71.051L64.888 64.887" />
          <path pathLength="1" d="M81.359 46.165L72.642 46.165" />
        </g>
      </svg>
    </div>
  );
}

export default Compass;
