import * as React from "react";
import styles from '../styles/handshake.module.scss';

function Handshake(props) {
  return (
    <div className={styles.svg}>
      <svg viewBox="0 0 262 163" {...props} className="animate animate--draw">
        <g
          fill="none"
          fillRule="evenodd"
          stroke="#102666"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={5}
        >
          <path
            pathLength="1" d="M0 0c4.929 2.972 13.216 8.152 18.396 10.224 20.717 9.322 56.625 22.061 63.371 25.966C69.752 54.528 62.358 75.3 53.895 95.388c-2.416 5.732-4.922 11.427-7.654 17.016-14.595-7.342-31.262-15.72-45.856-23.062m74.108-40.57c17.063 2.39 33.81-1.486 50.626 2.269"
            transform="translate(-1 3)"
          />
          <path
            pathLength="1" d="M144.861 98.34c10.271 12.365 22.703 21.688 35.134 32.047 4.143 2.072 7.251 4.143 12.43 4.143 6.215-1.035 8.288-6.215 7.251-11.394-1.036-3.108-4.143-5.18-6.215-7.252-19.682-14.502-36.256-31.076-56.974-43.507-7.25 7.251-15.538 14.503-26.934 16.574-4.143 0-9.323-3.108-7.25-7.25 2.071-3.109 4.143-7.253 7.25-10.36 4.144-6.215 5.18-14.502 12.431-18.646 17.61-9.323 35.221-2.072 53.867 2.072 8.287 1.035 15.538-1.036 23.825-3.108L209 48.551c.303-.1.597-.212.882-.33 2.671-1.113 5.155-2.21 7.848-3.265 8.19-3.208 16.06-7.167 23.917-11.12 7.426-3.736 14.874-7.485 21.775-12.12m-133.13 87.3a276.367 276.367 0 0029.692 27.714c2.728 2.194 5.55 4.367 8.818 5.624 3.266 1.259 7.088 1.5 10.183-.135 3.097-1.635 5.134-5.442 4.06-8.774m-80.56-16.816c-.522-3.076-2.92-5.754-5.919-6.612-3-.858-5.567.147-7.638 2.481-2.749 3.1-5.513 6.509-8.044 9.791m31.715 13.014c-2.086 3.528-4.282 7.175-7.687 9.455-3.407 2.279-8.394 2.753-11.388-.045-2.385-2.228-2.884-5.931-2.08-9.095.803-3.163 2.694-5.922 4.55-8.607l3.16-4.574c1.27-1.837 2.616-3.742 4.587-4.794 2.628-1.401 5.95-.978 8.553.467 2.1 1.166 6.31 4.385 6.279 6.987-.032 2.66-4.593 7.87-5.974 10.206zm-31.852-12.885c-1.076 1.864-1.958 3.892-2.138 6.037-.179 2.144.422 4.428 1.958 5.935 2.47 2.422 6.412 2.217 9.85 1.831m17.252 7.796c.54 2.963 1.493 6.392 4.306 7.47.899.345 1.882.39 2.845.418 1.633.049 3.29.06 4.873-.343 3.129-.795 5.642-3.12 7.677-5.626 2.522-3.105 4.452-5.92 5.855-9.664"
            transform="translate(-1 3)"
          />
          <path
            pathLength="1" d="M118.02 122.697a157.643 157.643 0 0119.187 17.106c2.993 3.143 5.893 6.442 9.494 8.865 3.599 2.423 8.08 3.904 12.309 2.934 4.23-.97 7.906-4.905 7.589-9.232m-41.107 6.663c.703 2.238 2.61 3.85 4.425 5.334 1.038.849 2.118 1.718 3.411 2.07 1.258.345 2.594.164 3.88-.062 2.13-.375 4.268-.88 6.172-1.91 1.902-1.03 3.563-2.644 4.264-4.69m-57.887-38.411c.119-3.784-3.657-6.897-7.442-7-3.785-.1-7.3 2.155-9.77 5.024-1.758 2.044-3.145 4.528-3.328 7.218-.182 2.69 1.05 5.575 3.437 6.827.95.498 2.015.719 3.065.934l2.703.554m117.554-8.273c4.737-1.799 8.88-5.943 14.06-6.979 17.61-5.179 37.293-5.179 54.167-11.178M50.9 102.8a91.791 91.791 0 0119 9"
            transform="translate(-1 3)"
          />
        </g>
      </svg>
    </div>

  );
}

export default Handshake;
