import * as React from "react";
import styles from '../styles/breakdown-5.module.scss';

function SvgComponent(props) {
  return (
    <div className={styles.svg}>
      <svg viewBox="0 0 97 97" {...props}>
      <defs>
        <path
          id="breakdown5-a"
          d="M0.0004 0.031L95.2817093 0.031 95.2817093 96.9998 0.0004 96.9998z"
        />
      </defs>
      <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <path
          d="M65.12 54.01c-1.988 7.326-8.68 12.715-16.636 12.715-9.52 0-17.24-7.718-17.24-17.24 0-9.498 7.682-17.201 17.171-17.238a17.174 17.174 0 0111.464 4.306c.865.763 1.655 1.61 2.352 2.53l23.163-17.028a46.193 46.193 0 00-6.236-6.826C71.02 7.937 60.271 3.499 48.484 3.499l-.096.003C23.036 3.556 2.5 24.12 2.5 49.486c0 25.397 20.588 45.984 45.984 45.984 21.108 0 38.892-14.222 44.297-33.606l-27.66-7.852z"
          fill="#D8D8D8"
          transform="translate(-1069 -2165) translate(1069 2164)"
        />
        <g transform="translate(-1069 -2165) translate(1069 2164) translate(0 .97)">
          <mask id="breakdown5-b" fill="#fff">
            <use xlinkHref="#breakdown5-a" />
          </mask>
          <path
            d="M48.484 5.03C24.465 5.084 5 24.59 5 48.516 5 72.493 24.506 92 48.484 92c18.611 0 35.188-11.97 41.151-29.401l-22.922-6.506c-3.022 7.27-10.194 12.163-18.229 12.163-10.885 0-19.74-8.855-19.74-19.74 0-10.841 8.82-19.695 19.661-19.738h.008c4.882 0 9.512 1.75 13.12 4.93.371.328.731.67 1.077 1.025l19.218-14.128a43.945 43.945 0 00-4.338-4.484A43.402 43.402 0 0048.484 5.03m0 91.969C21.75 97 0 75.25 0 48.515 0 21.847 21.688.104 48.353.033l.131-.002a48.396 48.396 0 0132.342 12.367 48.761 48.761 0 016.573 7.195 2.5 2.5 0 01-.524 3.507L63.712 40.129a2.502 2.502 0 01-3.474-.505 14.858 14.858 0 00-2.013-2.166 14.727 14.727 0 00-9.74-3.682c-8.155.034-14.74 6.645-14.74 14.74 0 8.128 6.611 14.74 14.74 14.74 6.637 0 12.485-4.47 14.222-10.87a2.5 2.5 0 013.095-1.75l27.661 7.853a2.499 2.499 0 011.726 3.076C89.371 82.428 70.166 97 48.484 97"
            fill="#102666"
            mask="url(#breakdown5-b)"
          />
        </g>
        <path
          d="M85.394 22.055L62.231 39.084a17.16 17.16 0 013.494 10.4c0 1.568-.213 3.084-.604 4.527l27.66 7.852a46.03 46.03 0 001.688-12.378 45.78 45.78 0 00-9.075-27.43"
          fill="#0079B9"
          transform="translate(-1069 -2165) translate(1069 2164)"
        />
        <path
          d="M68.031 52.238l22.946 6.513c.658-3.033.991-6.139.991-9.267 0-8.593-2.464-16.806-7.15-23.902L65.627 39.69a19.552 19.552 0 012.596 9.795c0 .92-.064 1.841-.193 2.753m24.75 12.124c-.229 0-.459-.03-.683-.095l-27.66-7.852a2.499 2.499 0 01-1.73-3.059c.343-1.265.516-2.567.516-3.87 0-3.24-1.032-6.315-2.985-8.893a2.497 2.497 0 01.512-3.524L83.914 20.04a2.501 2.501 0 013.486.522c6.26 8.41 9.568 18.41 9.568 28.922a48.56 48.56 0 01-1.779 13.05 2.502 2.502 0 01-2.408 1.828"
          fill="#102666"
          transform="translate(-1069 -2165) translate(1069 2164)"
        />
      </g>
    </svg>
    </div>
  );
}

export default SvgComponent;
