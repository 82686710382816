import * as React from "react";
import styles from '../styles/returns.module.scss';

function SvgComponent(props) {
  return (
    <div className={styles.svg}>
      <svg viewBox="0 0 237 237" {...props} className="animate animate--draw">
        <g
          transform="translate(-94 -4916) translate(96 4918)"
          stroke="none"
          strokeWidth={1}
          fill="none"
          fillRule="evenodd"
        >
          <path
            pathLength="1"
            fill="#DB8725"
            d="M203.0613 101.1266L102.4303 201.7576 51.0213 150.3486 151.6523 49.7176z"
          />
          <path
            pathLength="1"
            stroke="#102666"
            strokeWidth={4.024}
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M203.0613 101.1266L102.4303 201.7576 51.0213 150.3486 151.6523 49.7176z"
          />
          <g transform="translate(0 .322)">
            <path
              pathLength="1"
              d="M166.357 64.101c-8.12 8.121-21.289 8.121-29.41 0M188.357 115.512c-8.12-8.121-8.12-21.29 0-29.41M87.962 186.497c8.12-8.121 21.289-8.121 29.41 0M65.962 135.086c8.12 8.121 8.12 21.29 0 29.411M162.936 89.522a6.41 6.41 0 11-9.066 9.065 6.41 6.41 0 019.066-9.065zM144.348 108.11c9.56 9.558 9.56 25.056 0 34.614-9.558 9.558-25.055 9.558-34.613 0-9.559-9.558-9.559-25.056 0-34.614 9.558-9.558 25.055-9.558 34.613 0zM100.213 152.246a6.41 6.41 0 11-9.066 9.065 6.41 6.41 0 019.066-9.065z"
              stroke="#102666"
              strokeWidth={4.024}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              pathLength="1"
              d="M123.991 112.085c-1.266.078-2.323.948-3.283 1.776-1.339 1.156-2.759 2.447-3.088 4.185-.419 2.213 1.069 4.307 2.6 5.959 3.842 4.148 8.621 7.694 10.884 12.876l8.606-8.606"
              stroke="#102666"
              strokeWidth={4.024}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              pathLength="1"
              stroke="#102666"
              strokeWidth={4.024}
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M120.7439 131.8728L129.8689 122.7478"
            />
            <path
              pathLength="1"
              fill="#FFF"
              d="M193.7654 91.5091L93.1344 192.1401 41.7254 140.7311 142.3574 40.1001z"
            />
            <path
              pathLength="1"
              stroke="#102666"
              strokeWidth={4.024}
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M193.7654 91.5091L93.1344 192.1401 41.7254 140.7311 142.3574 40.1001z"
            />
            <path
              pathLength="1"
              d="M157.062 54.806c-8.122 8.12-21.289 8.12-29.41 0M179.062 106.216c-8.122-8.12-8.122-21.289 0-29.41M78.666 177.201c8.122-8.12 21.289-8.12 29.41 0M56.666 125.79c8.122 8.123 8.122 21.29 0 29.412M153.64 80.227a6.409 6.409 0 010 9.065 6.41 6.41 0 11-9.064-9.065 6.41 6.41 0 019.064 0zM135.054 98.814c9.559 9.558 9.559 25.056 0 34.614-9.56 9.558-25.056 9.558-34.615 0-9.557-9.558-9.557-25.056 0-34.614 9.559-9.558 25.056-9.558 34.615 0zM90.917 142.95a6.41 6.41 0 11-9.066 9.066 6.41 6.41 0 019.066-9.065z"
              stroke="#102666"
              strokeWidth={4.024}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              pathLength="1"
              d="M114.695 102.79c-1.266.077-2.323.947-3.283 1.775-1.339 1.156-2.759 2.447-3.088 4.185-.418 2.213 1.07 4.307 2.6 5.96 3.843 4.147 8.621 7.693 10.884 12.875l8.607-8.606"
              stroke="#102666"
              strokeWidth={4.024}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              pathLength="1"
              stroke="#102666"
              strokeWidth={4.024}
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M111.449 122.5774L120.573 113.4524"
            />
            <path
              pathLength="1"
              fill="#A5CB25"
              d="M183.1375 80.8807L82.5065 181.5117 31.0975 130.1027 131.7285 29.4717z"
            />
            <path
              pathLength="1"
              stroke="#102666"
              strokeWidth={4.024}
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M183.1375 80.8807L82.5065 181.5117 31.0975 130.1027 131.7285 29.4717z"
            />
            <path
              pathLength="1"
              d="M146.433 44.177c-8.12 8.121-21.289 8.121-29.41 0M168.433 95.588c-8.12-8.122-8.12-21.29 0-29.411M68.038 166.573c8.12-8.121 21.289-8.121 29.41 0M46.038 115.162c8.12 8.121 8.12 21.29 0 29.411M143.012 69.598a6.41 6.41 0 11-9.066 9.065 6.41 6.41 0 019.066-9.065zM124.425 88.186c9.559 9.558 9.559 25.056 0 34.614-9.558 9.558-25.055 9.558-34.613 0-9.56-9.558-9.56-25.056 0-34.614 9.558-9.558 25.055-9.558 34.613 0zM80.289 132.322a6.41 6.41 0 11-9.066 9.065 6.41 6.41 0 019.066-9.065z"
              stroke="#102666"
              strokeWidth={4.024}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              pathLength="1"
              d="M104.067 92.16c-1.266.079-2.324.949-3.284 1.777-1.339 1.156-2.759 2.447-3.087 4.185-.419 2.213 1.07 4.307 2.6 5.959 3.842 4.148 8.62 7.694 10.884 12.876l8.606-8.606"
              stroke="#102666"
              strokeWidth={4.024}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              pathLength="1"
              stroke="#102666"
              strokeWidth={4.024}
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M100.8201 111.9489L109.9451 102.8249"
            />
            <path
              pathLength="1"
              d="M15.056 173.75C-9.537 129.274-2.966 72.178 34.767 34.445c45.688-45.689 119.767-45.689 165.455 0"
              stroke="#102666"
              strokeWidth={5}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              pathLength="1"
              stroke="#102666"
              strokeWidth={5}
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M202.0857 13.9273L202.0857 36.3083 179.7047 36.3083"
            />
            <path
              pathLength="1"
              d="M216.933 58.905c25.104 44.08 18.854 101.165-18.75 138.77-45.102 45.1-118.224 45.1-163.323 0"
              stroke="#102666"
              strokeWidth={5}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              pathLength="1"
              stroke="#102666"
              strokeWidth={5}
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M31.4832 216.3904L31.4832 194.2974 53.5762 194.2974"
            />
          </g>
        </g>
      </svg>
    </div>
  );
}

export default SvgComponent;
