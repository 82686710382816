import React from 'react'
import Search from '../svgs/search.js'
import Employ from './svgs/employ.js'
import Charity from './svgs/charity.js'
import Returns from './svgs/returns.js'
import Invest from './svgs/invest.js'
import Subsidy from './svgs/subsidy.js'
import Risk from './svgs/risk.js'
import Modal1 from './modals/modal-1.js'
import Modal2 from './modals/modal-2.js'
import Modal4 from './modals/modal-4.js'

const Results = (props) => {
  return (
    <section className="section" id="results">
      <div className="animate animate--section">
        <div className="section__tab">
          <h3 className="h-m h-w-b">Results</h3>
        </div>
        <div className="blocks blocks--tab">
          <div className="block block-l-6w blocks">
            <div className="block block--text pad pad--s animate animate--fb">
              <h2 className="h-xl">What have we learnt from Futurebuilders?</h2>
            </div>
            <div className="block block--blue block--s animate animate--ft"></div>
          </div>
          <div className="block block-l-6w block--text pad pad--s pad--m pad animate animate--fr">
            <p>Our analysis of the Futurebuilders portfolio has provided answers to longstanding questions around ‘what makes social investment work’. In particular, there are six key lessons to be drawn from the Futurebuilders experience...</p>
          </div>
          <div className="block block-l-3w icon pad-top pad-top--s pad-bottom pad-bottom--s animate animate--fl animate--check-safari">
            <Employ/>
          </div>
          <div className="block block-l-9w block--text pad pad--s pad--m animate animate--fb">
            <h3 className="h-l h-w-m">1. It creates long-term employment:</h3>
            <p>Social sector organisations are productive and enterprising – This investment approach could work efficiently to generate jobs, grow incomes and create fairer employment. Our data shows that three years after receiving Futurebuilders investment, organisations increased their employment figures by 16%, and wages increased alongside business growth.</p>
            <button className="btn" onClick={props.toggleModal} data-modal="results-modal-1">
              <Search/>
              <span className="h-w-m">Show graph</span>
            </button>
          </div>
          <div className="block block-l-9w block--text pad pad--s pad--m animate animate--ft">
            <h3 className="h-l h-w-m">2. It improves the financial performance of charities and social enterprises</h3>
            <p>Key indicators of financial performance - such as Turnover, Net Assets, and Cash – all increased for 3-4 years after receiving Futurebuilders investment; ultimately reaching a new and higher plateau. Following investment from Futurebuilders, charities and social enterprises have also been shown to be more sustainable, with profit cycles showing as positive on balance.</p>
            <button className="btn" onClick={props.toggleModal} data-modal="results-modal-2">
              <Search/>
              <span className="h-w-m">Show graph</span>
            </button>
          </div>
          <div className="block block-l-3w icon icon--bottom pad-top pad-top--s animate animate--fr animate--check-safari">
            <Charity/>
          </div>
          <div className="block block-l-3w icon pad pad--s animate animate--fl animate--check-safari">
            <Returns/>
          </div>
          <div className="block block-l-9w block--text pad pad--s pad--m animate animate--fb">
            <h3 className="h-l h-w-m">3. It generates tangible financial returns for investors:</h3>
            <p>Of the £144 million invested, £98 million has already been returned (with the Fund still with 16 more years until closure).</p>
          </div>
          <div className="block block-l-9w block--text pad pad--s pad--m animate animate--ft">
            <h3 className="h-l h-w-m">4. It supports more affordable investment offers:</h3>
            <p>Larger grants by proportion went to smaller organisations; this kind of subsidy enables smaller organisations to take on investment and become stronger and more resilient. Subsidised loan rates were 2.14% based on initial offer terms (smaller if considering the financial interest rate variations offered during investment management).</p>
          </div>
          <div className="block block-l-3w icon icon--left pad-top pad-top--s pad-bottom pad-bottom--s pad-right pad-right--s animate animate--fl animate--check-safari">
            <Invest/>
          </div>
          <div className="block block-l-3w icon icon--left pad-top pad-top--s pad-bottom pad-bottom--s animate animate--fr animate--check-safari">
            <Subsidy/>
          </div>
          <div className="block block-l-9w block--text pad pad--s pad--m animate animate--fb">
            <h3 className="h-l h-w-m">5. It needs subsidy to be most effective:</h3>
            <p>The IRR on loans is 1.2% at time of writing, which is anticipated to rise. This has been subsidised by long-term business support and portfolio management costs: if these are taken into account, the IRR is around -8%. This subsidy has been essential to achieving the financial performance, employment growth and impact detailed elsewhere.</p>
            <button className="btn" onClick={props.toggleModal} data-modal="results-modal-4">
              <Search/>
              <span className="h-w-m">Show graph</span>
            </button>
          </div>
          <div className="block block-l-9w block--text pad pad--s pad--m animate animate--fl">
            <h3 className="h-l h-w-m">6. It absorbs risk through subsidies:</h3>
            <p>Operating on small profit margins (with maximum median profit at £30k), subsidies through variations, flexibility, use of blend and longer time horizons, have kept write-offs and provisions at only 17% (in 2019), against an original target of 25%. Despite the purpose of Futurebuilders being one of higher risk, specifically to test use of repayable finance in the third sector, the fund has exceeded expectations in terms of financial performance.</p>
          </div>
          <div className="block block-l-3w icon icon--bottom pad-top pad-top--s animate animate--fb animate--check-safari">
            <Risk/>
          </div>
          <div className="block block--blue animate animate--fr"></div>
        </div>
        <a href="#page" onClick={props.scrollPage} className="section__tab section__tab--bottom">
          <h3 className="h-m h-w-b">
            Back to top
          </h3>
        </a>
      </div>

      <Modal1
        activeModal={props.activeModal}
        toggleModal={props.toggleModal}
      />

      <Modal2
        activeModal={props.activeModal}
        toggleModal={props.toggleModal}
      />

      <Modal4
        activeModal={props.activeModal}
        toggleModal={props.toggleModal}
      />
    </section>
  )
}

export default Results
