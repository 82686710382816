import * as React from "react";
import styles from '../styles/modal-1-pie-2.module.scss'

function SvgComponent(props) {
  return (
    <div className={styles.svg}>
      <svg viewBox="0 0 166 166" {...props}>
        <g fill="none" fillRule="evenodd" stroke="#102666" strokeWidth={5}>
          <path
            fill="#D8D8D8"
            d="M80 0c44.183 0 80 35.817 80 80s-35.817 80-80 80S0 124.183 0 80 35.817 0 80 0zm0 37c-23.748 0-43 19.252-43 43s19.252 43 43 43 43-19.252 43-43-19.252-43-43-43z"
            transform="translate(3 3)"
          />
          <path
            fill="#D74EA5"
            d="M80 0c44.183 0 80 35.817 80 80s-35.817 80-80 80c-32.56 0-60.578-19.452-73.065-47.37L40.728 97.54C47.439 112.544 62.498 123 80 123c23.748 0 43-19.252 43-43s-19.252-43-43-43h-.008l-.007-37H80z"
            transform="translate(3 3)"
          />
        </g>
      </svg>
    </div>
  );
}

export default SvgComponent;
