import * as React from "react";
import styles from '../styles/breakdown-2.module.scss';

function SvgComponent(props) {
  return (
    <div className={styles.svg}>
      <svg viewBox="0 0 97 97" {...props}>
      <defs>
        <path
          id="breakdown1-a"
          d="M0.0004 0.031L96.9684 0.031 96.9684 96.9999 0.0004 96.9999z"
        />
      </defs>
      <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <path
          d="M85.394 22.055L62.231 39.084a17.16 17.16 0 013.494 10.4c0 1.568-.213 3.084-.604 4.527-1.99 7.325-8.682 12.714-16.636 12.714-9.523 0-17.24-7.72-17.24-17.24 0-9.498 7.68-17.2 17.17-17.24h.07c4.368 0 8.355 1.628 11.392 4.308L79.16 15.228C71.02 7.937 60.27 3.5 48.484 3.5h-.097v.002C23.038 3.555 2.5 24.12 2.5 49.485c0 25.396 20.588 45.984 45.985 45.984 21.107 0 38.892-14.223 44.297-33.606a46.036 46.036 0 001.688-12.378 45.78 45.78 0 00-9.075-27.43"
          fill="#D8D8D8"
          transform="translate(-739 -1901) translate(739 1900)"
        />
        <g transform="translate(-739 -1901) translate(739 1900) translate(0 .969)">
          <mask id="breakdown1-b" fill="#fff">
            <use xlinkHref="#breakdown1-a" />
          </mask>
          <path
            d="M48.484 33.776c-8.154.034-14.74 6.646-14.74 14.74 0 8.128 6.612 14.74 14.74 14.74 6.638 0 12.486-4.47 14.223-10.87.343-1.264.517-2.566.517-3.87 0-3.24-1.032-6.314-2.985-8.891a2.497 2.497 0 01-.508-1.546 2.489 2.489 0 01-1.507-.62 14.717 14.717 0 00-9.74-3.683m0-28.745C24.465 5.083 5 24.589 5 48.516 5 72.493 24.506 92 48.484 92c19.446 0 36.671-13.067 41.89-31.778a43.598 43.598 0 001.594-11.706c0-8.594-2.464-16.806-7.152-23.903L65.627 38.72a19.555 19.555 0 012.597 9.795c0 1.746-.232 3.489-.69 5.18-2.328 8.573-10.16 14.56-19.05 14.56-10.885 0-19.74-8.855-19.74-19.74 0-10.84 8.82-19.695 19.66-19.74h.006c4.041 0 7.908 1.2 11.165 3.415l15.996-17.692A43.406 43.406 0 0048.484 5.03m0 91.969C21.75 97 0 75.25 0 48.516 0 21.838 21.704.088 48.382.033l.102-.002a48.396 48.396 0 0132.342 12.367c.495.443.794 1.066.828 1.73a2.495 2.495 0 01-.642 1.808L66.805 31.649l17.11-12.577a2.5 2.5 0 013.485.52c6.26 8.41 9.568 18.412 9.568 28.924a48.56 48.56 0 01-1.779 13.05C89.371 82.429 70.166 97 48.484 97"
            fill="#102666"
            mask="url(#breakdown1-b)"
          />
        </g>
        <path
          d="M62.231 39.084l23.163-17.03a46.298 46.298 0 00-6.236-6.825l-19.28 21.324c.866.763 1.655 1.61 2.353 2.53"
          fill="#A5CB25"
          transform="translate(-739 -1901) translate(739 1900)"
        />
        <path
          d="M79.296 18.803L66.808 32.616l15.02-11.043a43.615 43.615 0 00-2.532-2.77m-17.062 22.78a2.501 2.501 0 01-1.995-.989 14.885 14.885 0 00-2.013-2.167 2.5 2.5 0 01-.201-3.552l19.279-21.324a2.5 2.5 0 013.522-.185 48.867 48.867 0 016.574 7.196 2.497 2.497 0 01-.525 3.507L63.712 41.097a2.484 2.484 0 01-1.478.486"
          fill="#102666"
          transform="translate(-739 -1901) translate(739 1900)"
        />
      </g>
    </svg>
    </div>
  );
}

export default SvgComponent;
