import * as React from "react";
import styles from '../styles/breakdown-1.module.scss';

function SvgComponent(props) {
  return (
    <div className={styles.svg}>
      <svg viewBox="0 0 97 97" {...props}>
      <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <path
          d="M85.394 21.055a46.303 46.303 0 00-6.236-6.826l-19.28 21.324c.867.763 1.655 1.61 2.353 2.531a17.161 17.161 0 013.494 10.401c0 1.567-.213 3.083-.604 4.526-1.99 7.325-8.682 12.714-16.636 12.714-9.523 0-17.24-7.718-17.24-17.24 0-9.498 7.68-17.2 17.17-17.238l.046-28.746C23.076 2.513 2.498 23.096 2.498 48.485c0 25.396 20.588 45.985 45.985 45.985 21.107 0 38.892-14.223 44.297-33.607a46.03 46.03 0 001.688-12.378 45.78 45.78 0 00-9.075-27.43"
          fill="#D8D8D8"
          transform="translate(-741 -1765) translate(741 1765)"
        />
        <path
          d="M45.957 5.074C23.153 6.387 5 25.36 5 48.484 5 72.464 24.507 91.97 48.484 91.97c19.447 0 36.671-13.068 41.889-31.778a43.566 43.566 0 001.596-11.707c0-9.427-2.967-18.396-8.58-25.937h.001a43.95 43.95 0 00-4.094-4.744L63.322 35.47c.315.357.615.726.902 1.103a19.57 19.57 0 014.001 11.912c0 1.746-.233 3.489-.692 5.18-2.327 8.573-10.16 14.56-19.049 14.56-10.884 0-19.74-8.856-19.74-19.74 0-10 7.503-18.308 17.175-19.572l.038-23.84zm2.527 91.896C21.75 96.97 0 75.22 0 48.485 0 21.764 21.739.014 48.46 0L50.965 0l-.054 33.738-2.486.009c-8.095.033-14.681 6.644-14.681 14.739 0 8.128 6.612 14.74 14.74 14.74 6.638 0 12.487-4.47 14.223-10.87.344-1.264.518-2.566.518-3.87 0-3.24-1.033-6.314-2.986-8.892a14.875 14.875 0 00-2.013-2.165l-1.901-1.674 22.648-25.048 1.853 1.66a48.812 48.812 0 016.573 7.195h.001c6.26 8.41 9.569 18.412 9.569 28.923 0 4.426-.599 8.817-1.78 13.05C89.371 82.398 70.166 96.97 48.484 96.97z"
          fill="#102666"
          transform="translate(-741 -1765) translate(741 1765)"
        />
        <path
          d="M48.484 31.245c4.37 0 8.355 1.628 11.394 4.308l19.28-21.325C71.02 6.938 60.27 2.5 48.484 2.5l-.097.001v28.746l.097-.002"
          fill="#DB8725"
          transform="translate(-741 -1765) translate(741 1765)"
        />
        <path
          d="M50.888 28.892a19.703 19.703 0 018.688 3.268l15.996-17.692a43.395 43.395 0 00-24.684-9.402v23.826zm9.188 10.17l-1.851-1.634a14.717 14.717 0 00-9.712-3.683l-.125.002h-2.5V.001h2.504c12.05 0 23.534 4.391 32.434 12.366l1.872 1.675-22.622 25.02z"
          fill="#102666"
          transform="translate(-741 -1765) translate(741 1765)"
        />
      </g>
    </svg>
    </div>
  );
}

export default SvgComponent;
