import React from 'react'
import Close from '../../svgs/close.js'
import Bars1 from '../svgs/modal-2-bars-1.js'

const Modal = (props) => {
  return (
    <div className={`modal ${props.activeModal === 'results-modal-2' ? 'modal-is-active' : ''}`}>
      <div className="modal__container">
        <div className="modal__content">
          <div className="blocks">
            <div className="row row--blue row--j-e">
              <button className="modal__close" onClick={props.toggleModal}>
                <span className="visually-hidden">Close modal</span>
                <Close/>
              </button>
            </div>
            <div className="block icon pad pad--m">
              <Bars1/>
            </div>
            <div className="row row--blue"></div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Modal
