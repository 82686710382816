import * as React from "react";
import styles from '../styles/chain.module.scss';

function SvgComponent(props) {
  return (
    <div className={styles.svg}>
      <svg viewBox="0 0 170 84" {...props} className="animate animate--draw">
        <g
          transform="translate(-1166 -6918) translate(56 6434) translate(1112 485)"
          stroke="none"
          strokeWidth={1}
          fill="none"
          fillRule="evenodd"
        >
          <path
            pathLength="1"
            stroke="#102666"
            strokeWidth={5}
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M12.243 68.629L61.346 26.685 98.999 64.093 153.461 13.243"
          />
          <path
            pathLength="1"
            d="M110.741 63.221c0 6.485-5.257 11.743-11.742 11.743-6.486 0-11.743-5.258-11.743-11.743S92.513 51.478 99 51.478c6.485 0 11.742 5.258 11.742 11.743"
            fill="#FFF"
            transform="translate(0 .872)"
          />
          <path
            pathLength="1"
            d="M110.741 63.221c0 6.485-5.257 11.743-11.742 11.743-6.486 0-11.743-5.258-11.743-11.743S92.513 51.478 99 51.478c6.485 0 11.742 5.258 11.742 11.743z"
            stroke="#102666"
            strokeWidth={5}
            strokeLinecap="round"
            strokeLinejoin="round"
            transform="translate(0 .872)"
          />
          <path
            pathLength="1"
            d="M73.089 25.813c0 6.485-5.258 11.743-11.743 11.743-6.486 0-11.742-5.258-11.742-11.743S54.86 14.07 61.346 14.07c6.485 0 11.743 5.258 11.743 11.743"
            fill="#FFF"
            transform="translate(0 .872)"
          />
          <path
            pathLength="1"
            d="M73.089 25.813c0 6.485-5.258 11.743-11.743 11.743-6.486 0-11.742-5.258-11.742-11.743S54.86 14.07 61.346 14.07c6.485 0 11.743 5.258 11.743 11.743z"
            stroke="#102666"
            strokeWidth={5}
            strokeLinecap="round"
            strokeLinejoin="round"
            transform="translate(0 .872)"
          />
          <path
            pathLength="1"
            d="M23.985 67.757c0 6.485-5.257 11.743-11.742 11.743C5.757 79.5.5 74.242.5 67.757s5.257-11.743 11.743-11.743c6.485 0 11.742 5.258 11.742 11.743"
            fill="#FFF"
            transform="translate(0 .872)"
          />
          <path
            pathLength="1"
            d="M23.985 67.757c0 6.485-5.257 11.743-11.742 11.743C5.757 79.5.5 74.242.5 67.757s5.257-11.743 11.743-11.743c6.485 0 11.742 5.258 11.742 11.743z"
            stroke="#102666"
            strokeWidth={5}
            strokeLinecap="round"
            strokeLinejoin="round"
            transform="translate(0 .872)"
          />
          <path
            pathLength="1"
            d="M165.204 12.37c0 6.486-5.258 11.744-11.743 11.744-6.486 0-11.742-5.258-11.742-11.743S146.975.628 153.461.628c6.485 0 11.743 5.258 11.743 11.743"
            fill="#FFF"
            transform="translate(0 .872)"
          />
          <path
            pathLength="1"
            d="M165.204 12.37c0 6.486-5.258 11.744-11.743 11.744-6.486 0-11.742-5.258-11.742-11.743S146.975.628 153.461.628c6.485 0 11.743 5.258 11.743 11.743z"
            stroke="#102666"
            strokeWidth={5}
            strokeLinecap="round"
            strokeLinejoin="round"
            transform="translate(0 .872)"
          />
        </g>
      </svg>
    </div>
  );
}

export default SvgComponent;
