import * as React from "react";
import styles from '../styles/cash.module.scss';

function SvgComponent(props) {
  return (
    <div className={styles.svg}>
      <svg viewBox="0 0 157 113" {...props} className="animate animate--draw">
        <g
          transform="translate(-751 -1185) translate(719 1131) translate(35.184 57.012)"
          stroke="none"
          strokeWidth={1}
          fill="none"
          fillRule="evenodd"
        >
          <path
            pathLength="1"
            fill="#A5CB25"
            d="M33.005 60.221L150.885 60.221 150.885 0 33.005 0z"
          />
          <path
            pathLength="1"
            stroke="#102666"
            strokeWidth={5}
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M33.005 60.221L150.885 60.221 150.885 0 33.005 0z"
          />
          <path
            pathLength="1"
            d="M150.884 17.226c-9.514 0-17.226-7.712-17.226-17.226M133.659 60.223c0-9.513 7.712-17.226 17.226-17.226M33.28 42.997c9.515 0 17.227 7.712 17.227 17.226M50.506 0c0 9.514-7.712 17.226-17.226 17.226M133.992 30.112a5.31 5.31 0 11-10.619 0 5.31 5.31 0 0110.619 0zM112.218 30.112c0 11.196-9.077 20.273-20.273 20.273-11.197 0-20.274-9.077-20.274-20.273 0-11.197 9.077-20.273 20.274-20.273 11.196 0 20.273 9.076 20.273 20.273zM60.517 30.112a5.31 5.31 0 11-10.62-.002 5.31 5.31 0 0110.62.002z"
            stroke="#102666"
            strokeWidth={5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            pathLength="1"
            d="M97.966 20.516c-.787-.696-1.916-.806-2.964-.882-1.46-.108-3.049-.183-4.26.642-1.54 1.051-1.895 3.149-1.966 5.012-.179 4.681.543 9.557-1.167 13.917H97.69"
            stroke="#102666"
            strokeWidth={5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            pathLength="1"
            stroke="#102666"
            strokeWidth={5}
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M84.4751 30.2045L95.1631 30.2045"
          />
          <path
            pathLength="1"
            fill="#FFF"
            d="M16.502 83.724L134.382 83.724 134.382 23.503 16.502 23.503z"
          />
          <path
            pathLength="1"
            stroke="#102666"
            strokeWidth={5}
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M16.502 83.724L134.382 83.724 134.382 23.503 16.502 23.503z"
          />
          <path
            pathLength="1"
            d="M134.382 40.729c-9.514 0-17.227-7.712-17.227-17.226M117.156 83.725c0-9.513 7.713-17.225 17.227-17.225M16.778 66.5c9.514 0 17.226 7.711 17.226 17.225M34.003 23.503c0 9.514-7.712 17.226-17.226 17.226M117.489 53.614a5.31 5.31 0 11-10.618 0 5.31 5.31 0 0110.618 0zM95.715 53.614c0 11.197-9.076 20.273-20.273 20.273s-20.274-9.076-20.274-20.273 9.077-20.273 20.274-20.273c11.197 0 20.273 9.076 20.273 20.273zM44.014 53.614a5.31 5.31 0 11-10.618 0 5.31 5.31 0 0110.618 0z"
            stroke="#102666"
            strokeWidth={5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            pathLength="1"
            d="M81.463 44.018c-.787-.696-1.916-.806-2.964-.882-1.46-.108-3.049-.183-4.259.642-1.54 1.051-1.896 3.15-1.967 5.013-.179 4.681.543 9.556-1.166 13.917h10.081"
            stroke="#102666"
            strokeWidth={5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            pathLength="1"
            stroke="#102666"
            strokeWidth={5}
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M67.9722 53.7069L78.6602 53.7069"
          />
          <path
            pathLength="1"
            fill="#DB8725"
            d="M-5.68434189e-14 107.226L117.879 107.226 117.879 47.006 -5.68434189e-14 47.006z"
          />
          <path
            pathLength="1"
            stroke="#102666"
            strokeWidth={5}
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M-5.68434189e-14 107.226L117.879 107.226 117.879 47.006 -5.68434189e-14 47.006z"
          />
          <path
            pathLength="1"
            d="M117.88 64.231c-9.515 0-17.227-7.712-17.227-17.226M100.654 107.228c0-9.514 7.712-17.226 17.226-17.226M.276 90.002c9.513 0 17.226 7.712 17.226 17.226M17.502 47.006c0 9.513-7.713 17.226-17.226 17.226M100.987 77.117a5.31 5.31 0 11-10.62 0 5.31 5.31 0 0110.62 0zM79.213 77.117c0 11.197-9.077 20.273-20.273 20.273-11.198 0-20.274-9.076-20.274-20.273 0-11.196 9.076-20.273 20.274-20.273 11.196 0 20.273 9.077 20.273 20.273zM27.511 77.117a5.31 5.31 0 11-10.619 0 5.31 5.31 0 0110.62 0z"
            stroke="#102666"
            strokeWidth={5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            pathLength="1"
            d="M64.961 67.521c-.787-.696-1.917-.806-2.964-.883-1.46-.107-3.049-.182-4.26.643-1.54 1.051-1.895 3.15-1.966 5.012-.18 4.681.542 9.556-1.167 13.917h10.081"
            stroke="#102666"
            strokeWidth={5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            pathLength="1"
            stroke="#102666"
            strokeWidth={5}
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M51.4692 77.2099L62.1572 77.2099"
          />
        </g>
      </svg>
    </div>
  );
}

export default SvgComponent;
