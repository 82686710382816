import React from 'react'
import Close from '../../svgs/close.js'
import Percent from '../../svgs/percent.js'
import Bars1 from '../svgs/modal-4-bars-1.js'

const Modal = (props) => {
  return (
    <div className={`modal ${props.activeModal === 'results-modal-4' ? 'modal-is-active' : ''}`}>
      <div className="modal__container">
        <div className="modal__content">
          <div className="blocks">
            <div className="row row--blue row--j-e">
              <button className="modal__close" onClick={props.toggleModal}>
                <span className="visually-hidden">Close modal</span>
                <Close/>
              </button>
            </div>
            <div className="block block-l-6w blocks">
              <div className="block block--text pad pad--s">
                <h2 className="h-xl">FBE Internal Rate of Return (IRR) for Loans</h2>
              </div>
              <div className="block block-3w block--green"></div>
              <div className="block block-3w icon pad">
                <Percent/>
              </div>
              <div className="block block-3w"></div>
              <div className="block block-3w block--navy"></div>
            </div>
            <div className="block block-l-6w block--text pad pad--s pad--m">
              <p>Steadily increasing IRR reflects that longer loan terms correspond to higher returns – as of 1st May 2020, FBE IRR was 1.20%. The IRR has been calculated based on the fully closed book. We would expect IRR to continue to increase in future, as longer loan terms come into effect and larger proportions of the portfolio are closed.</p>
            </div>
            <div className="block icon pad pad--s pad--m">
              <Bars1/>
            </div>
            <div className="row row--blue"></div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Modal
