import * as React from "react";
import styles from '../styles/invest.module.scss'

function SvgComponent(props) {
  return (
    <div className={styles.svg}>
      <svg viewBox="0 0 326 279" {...props} className="animate animate--draw">
        <g
          transform="translate(-3 1)"
          stroke="none"
          strokeWidth={1}
          fill="none"
          fillRule="evenodd"
        >
          <path
            pathLength="1"
            fill="#FFF"
            d="M98.564 280.626L236.446 280.626 236.446 209.958 98.564 209.958z"
          />
          <path
            pathLength="1"
            stroke="#102666"
            strokeWidth={5}
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M98.564 280.626L236.446 280.626 236.446 209.958 98.564 209.958z"
          />
          <path
            pathLength="1"
            fill="#102666"
            d="M189.385 279.625L217.966 279.625 217.966 221.893 189.385 221.893z"
          />
          <path
            pathLength="1"
            stroke="#102666"
            strokeWidth={5}
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M189.385 279.625L217.966 279.625 217.966 221.893 189.385 221.893z"
          />
          <path
            pathLength="1"
            fill="#FFF"
            d="M117.54 257.455L167.505 257.455 167.505 225.59 117.54 225.59z"
          />
          <path
            pathLength="1"
            stroke="#102666"
            strokeWidth={5}
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M117.54 257.455L167.505 257.455 167.505 225.59 117.54 225.59z"
          />
          <path
            pathLength="1"
            fill="#DB8725"
            d="M230.0788 161.5889L103.2748 161.5889 87.2568 209.9579 246.9068 209.9579z"
          />
          <path
            pathLength="1"
            stroke="#102666"
            strokeWidth={5}
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M230.0788 161.5889L103.2748 161.5889 87.2568 209.9579 246.9068 209.9579z"
          />
          <path
            pathLength="1"
            fill="#FFF"
            d="M198.082 161.058L217.966 161.058 217.966 137.958 198.082 137.958z"
          />
          <path
            pathLength="1"
            stroke="#102666"
            strokeWidth={5}
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M198.082 161.058L217.966 161.058 217.966 137.958 198.082 137.958z"
          />
          <path
            pathLength="1"
            stroke="#102666"
            strokeWidth={5}
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M142.5222 226.5465L142.5222 255.9095"
          />
          <path
            pathLength="1"
            fill="#FFF"
            d="M0.5 280.628L55.19 280.628 55.19 209.958 0.5 209.958z"
          />
          <path
            pathLength="1"
            stroke="#102666"
            strokeWidth={5}
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M0.5 280.628L55.19 280.628 55.19 209.958 0.5 209.958z"
          />
          <path
            pathLength="1"
            fill="#FFF"
            d="M0.5 257.458L36.21 257.458 36.21 225.588 0.5 225.588z"
          />
          <path
            pathLength="1"
            stroke="#102666"
            strokeWidth={5}
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M0.5 257.458L36.21 257.458 36.21 225.588 0.5 225.588z"
          />
          <path
            pathLength="1"
            fill="#D74EA5"
            d="M66.4997 209.9581L0.4997 209.9581 0.4997 161.5881 50.4797 161.5881z"
          />
          <path
            pathLength="1"
            stroke="#102666"
            strokeWidth={5}
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M66.4997 209.9581L0.4997 209.9581 0.4997 161.5881 50.4797 161.5881z"
          />
          <path
            pathLength="1"
            stroke="#102666"
            strokeWidth={5}
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M11.2302 226.5465L11.2302 255.9095"
          />
          <path
            pathLength="1"
            fill="#FFF"
            d="M278.32 280.628L331.66 280.628 331.66 209.958 278.32 209.958z"
          />
          <path
            pathLength="1"
            stroke="#102666"
            strokeWidth={5}
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M278.32 280.628L331.66 280.628 331.66 209.958 278.32 209.958z"
          />
          <path
            pathLength="1"
            fill="#FFF"
            d="M297.3 257.458L331.66 257.458 331.66 225.588 297.3 225.588z"
          />
          <path
            pathLength="1"
            stroke="#102666"
            strokeWidth={5}
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M297.3 257.458L331.66 257.458 331.66 225.588 297.3 225.588z"
          />
          <path
            pathLength="1"
            fill="#A5CB25"
            d="M331.6599 161.588L331.6599 209.958 267.0099 209.958 283.0299 161.588z"
          />
          <path
            pathLength="1"
            stroke="#102666"
            strokeWidth={5}
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M331.6599 161.588L331.6599 209.958 267.0099 209.958 283.0299 161.588z"
          />
          <path
            pathLength="1"
            stroke="#102666"
            strokeWidth={5}
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M322.28 226.5465L322.28 255.9095"
          />
          <path
            pathLength="1"
            fill="#A5CB25"
            d="M123.63 64.828L248.547 64.828 248.547 1.012 123.63 1.012z"
          />
          <g transform="translate(81 .128)">
            <path
              pathLength="1"
              stroke="#102666"
              strokeWidth={4.024}
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M42.63 64.7L167.547 64.7 167.547 0.884 42.63 0.884z"
            />
            <path
              pathLength="1"
              d="M167.547 19.138c-10.082 0-18.254-8.173-18.254-18.254M149.293 64.702c0-10.082 8.172-18.254 18.254-18.254M42.92 46.448c10.083 0 18.256 8.173 18.256 18.254M61.175.884c0 10.082-8.172 18.254-18.255 18.254M149.645 32.793a5.627 5.627 0 11-11.253-.001 5.627 5.627 0 0111.253.001zM126.572 32.793c0 11.865-9.619 21.484-21.484 21.484-11.866 0-21.484-9.619-21.484-21.484 0-11.865 9.618-21.484 21.484-21.484 11.865 0 21.484 9.62 21.484 21.484zM71.783 32.793a5.626 5.626 0 11-11.252 0 5.626 5.626 0 0111.252 0z"
              stroke="#102666"
              strokeWidth={4.024}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              pathLength="1"
              d="M111.469 22.625c-.834-.737-2.03-.854-3.141-.935-1.547-.114-3.23-.194-4.514.68-1.632 1.114-2.008 3.338-2.083 5.313-.191 4.96.574 10.126-1.237 14.748h10.683"
              stroke="#102666"
              strokeWidth={4.024}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              pathLength="1"
              stroke="#102666"
              strokeWidth={4.024}
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M97.1716 32.8916L108.4986 32.8916"
            />
            <path
              pathLength="1"
              fill="#FFF"
              d="M21.557 64.7L146.475 64.7 146.475 0.884 21.557 0.884z"
            />
            <path
              pathLength="1"
              stroke="#102666"
              strokeWidth={4.024}
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M21.557 64.7L146.475 64.7 146.475 0.884 21.557 0.884z"
            />
            <path
              pathLength="1"
              d="M146.474 19.138c-10.08 0-18.254-8.173-18.254-18.254M128.22 64.702c0-10.082 8.173-18.254 18.254-18.254M21.85 46.448c10.08 0 18.253 8.173 18.253 18.254M40.103.884c0 10.082-8.173 18.254-18.254 18.254M128.573 32.793a5.626 5.626 0 11-11.252 0 5.626 5.626 0 0111.252 0zM105.5 32.793c0 11.865-9.618 21.484-21.483 21.484-11.866 0-21.485-9.619-21.485-21.484 0-11.865 9.619-21.484 21.485-21.484 11.865 0 21.483 9.62 21.483 21.484zM50.712 32.793a5.627 5.627 0 11-11.255-.002 5.627 5.627 0 0111.255.002z"
              stroke="#102666"
              strokeWidth={4.024}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              pathLength="1"
              d="M90.397 22.625c-.834-.737-2.03-.854-3.14-.935-1.549-.114-3.232-.194-4.514.68-1.633 1.114-2.01 3.338-2.085 5.313-.19 4.96.575 10.126-1.237 14.748h10.683"
              stroke="#102666"
              strokeWidth={4.024}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              pathLength="1"
              stroke="#102666"
              strokeWidth={4.024}
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M76.1003 32.8916L87.4263 32.8916"
            />
            <path
              pathLength="1"
              fill="#DB8725"
              d="M0.486 64.7L125.403 64.7 125.403 0.884 0.486 0.884z"
            />
            <path
              pathLength="1"
              stroke="#102666"
              strokeWidth={4.024}
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M0.486 64.7L125.403 64.7 125.403 0.884 0.486 0.884z"
            />
            <path
              pathLength="1"
              d="M125.403 19.138c-10.082 0-18.255-8.173-18.255-18.254M107.148 64.702c0-10.082 8.173-18.254 18.255-18.254M.777 46.448c10.082 0 18.255 8.173 18.255 18.254M19.032.884c0 10.082-8.173 18.254-18.255 18.254M107.502 32.793a5.627 5.627 0 11-11.254-.002 5.627 5.627 0 0111.254.002zM84.428 32.793c0 11.865-9.619 21.484-21.484 21.484-11.866 0-21.484-9.619-21.484-21.484 0-11.865 9.618-21.484 21.484-21.484 11.865 0 21.484 9.62 21.484 21.484zM29.64 32.793a5.626 5.626 0 11-11.253 0 5.626 5.626 0 0111.252 0z"
              stroke="#102666"
              strokeWidth={4.024}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              pathLength="1"
              d="M69.325 22.625c-.834-.737-2.03-.854-3.141-.935-1.547-.114-3.23-.194-4.513.68-1.633 1.114-2.01 3.338-2.085 5.313-.19 4.96.575 10.126-1.236 14.748h10.683"
              stroke="#102666"
              strokeWidth={4.024}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              pathLength="1"
              stroke="#102666"
              strokeWidth={4.024}
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M55.028 32.8916L66.355 32.8916"
            />
            <path
              pathLength="1"
              stroke="#102666"
              strokeWidth={5}
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M102.6745 117.6968L85.1895 135.1818 67.7025 117.6968"
            />
            <path
              pathLength="1"
              stroke="#102666"
              strokeWidth={5}
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M85.1892 135.1821L85.1892 86.8901"
            />
          </g>
        </g>
      </svg>
    </div>
  );
}

export default SvgComponent;
