import * as React from "react";
import styles from '../styles/modal-2-bars-1.module.scss';

function SvgComponent(props) {
  return (
    <div className={styles.svg}>
      <svg viewBox="0 0 1191 702" {...props}>
        <defs>
          <path
            id="a"
            d="M0.000056390193 0.141032609L1080.79756 0.141032609 1080.79756 0.564130435 0.000056390193 0.564130435z"
          />
        </defs>
        <g
          transform="translate(-1)"
          stroke="none"
          strokeWidth={1}
          fill="none"
          fillRule="evenodd"
        >
          <g transform="translate(226 2)">
            <text
              fontFamily="Ubuntu-Regular, Ubuntu"
              fontSize={20}
              fontWeight="normal"
              fill="#102666"
            >
              <tspan x={44} y={19}>
                {"Median of Net Assets"}
              </tspan>
            </text>
            <text
              fontFamily="Ubuntu-Regular, Ubuntu"
              fontSize={20}
              fontWeight="normal"
              fill="#102666"
            >
              <tspan x={306} y={19}>
                {"Median of Total Assets"}
              </tspan>
            </text>
            <text
              fontFamily="Ubuntu-Regular, Ubuntu"
              fontSize={20}
              fontWeight="normal"
              fill="#102666"
            >
              <tspan x={582} y={19}>
                {"Median of Total Liabilities"}
              </tspan>
            </text>
            <circle
              stroke="#102666"
              strokeWidth={5}
              fill="#102666"
              cx={10}
              cy={12}
              r={10}
            />
            <circle
              stroke="#102666"
              strokeWidth={5}
              fill="#0079B9"
              cx={272}
              cy={12}
              r={10}
            />
            <circle
              stroke="#102666"
              strokeWidth={5}
              fill="#D74EA5"
              cx={548}
              cy={12}
              r={10}
            />
          </g>
          <path d="M0.5 0H1192.5V702H0.5z" />
          <text
            fontFamily="Ubuntu-Regular, Ubuntu"
            fontSize={20}
            fontWeight="normal"
            fill="#102666"
            transform="translate(1 74)"
          >
            <tspan x={555.03} y={611}>
              {"Timeline"}
            </tspan>
          </text>
          <g transform="translate(1 74)">
            <text
              fontFamily="Ubuntu-Regular, Ubuntu"
              fontSize={20}
              fontWeight="normal"
              fill="#102666"
            >
              <tspan x={125.37} y={559}>
                {"-2"}
              </tspan>
            </text>
            <text
              fontFamily="Ubuntu-Regular, Ubuntu"
              fontSize={20}
              fontWeight="normal"
              fill="#102666"
            >
              <tspan x={235.37} y={559}>
                {"-1"}
              </tspan>
            </text>
            <text
              fontFamily="Ubuntu-Regular, Ubuntu"
              fontSize={20}
              fontWeight="normal"
              fill="#102666"
            >
              <tspan x={348.36} y={559}>
                {"0"}
              </tspan>
            </text>
            <text
              fontFamily="Ubuntu-Regular, Ubuntu"
              fontSize={20}
              fontWeight="normal"
              fill="#102666"
            >
              <tspan x={458.36} y={559}>
                {"1"}
              </tspan>
            </text>
            <text
              fontFamily="Ubuntu-Regular, Ubuntu"
              fontSize={20}
              fontWeight="normal"
              fill="#102666"
            >
              <tspan x={568.36} y={559}>
                {"2"}
              </tspan>
            </text>
            <text
              fontFamily="Ubuntu-Regular, Ubuntu"
              fontSize={20}
              fontWeight="normal"
              fill="#102666"
            >
              <tspan x={672.36} y={559}>
                {"3"}
              </tspan>
            </text>
            <text
              fontFamily="Ubuntu-Regular, Ubuntu"
              fontSize={20}
              fontWeight="normal"
              fill="#102666"
            >
              <tspan x={782.36} y={559}>
                {"4"}
              </tspan>
            </text>
            <text
              fontFamily="Ubuntu-Regular, Ubuntu"
              fontSize={20}
              fontWeight="normal"
              fill="#102666"
            >
              <tspan x={892.36} y={559}>
                {"5"}
              </tspan>
            </text>
            <text
              fontFamily="Ubuntu-Regular, Ubuntu"
              fontSize={20}
              fontWeight="normal"
              fill="#102666"
            >
              <tspan x={1002.36} y={559}>
                {"6"}
              </tspan>
            </text>
            <text
              fontFamily="Ubuntu-Regular, Ubuntu"
              fontSize={20}
              fontWeight="normal"
              fill="#102666"
            >
              <tspan x={1112.36} y={559}>
                {"7"}
              </tspan>
            </text>
            <text
              fontFamily="Ubuntu-Regular, Ubuntu"
              fontSize={20}
              fontWeight="normal"
              fill="#102666"
            >
              <tspan x={0} y={537}>
                {"\xA30.0M"}
              </tspan>
            </text>
            <text
              fontFamily="Ubuntu-Regular, Ubuntu"
              fontSize={20}
              fontWeight="normal"
              fill="#102666"
            >
              <tspan x={0} y={93}>
                {"\xA31.2M"}
              </tspan>
            </text>
            <text
              fontFamily="Ubuntu-Regular, Ubuntu"
              fontSize={20}
              fontWeight="normal"
              fill="#102666"
            >
              <tspan x={0} y={19}>
                {"\xA31.4M"}
              </tspan>
            </text>
            <text
              fontFamily="Ubuntu-Regular, Ubuntu"
              fontSize={20}
              fontWeight="normal"
              fill="#102666"
            >
              <tspan x={0} y={167}>
                {"\xA31.0M"}
              </tspan>
            </text>
            <text
              fontFamily="Ubuntu-Regular, Ubuntu"
              fontSize={20}
              fontWeight="normal"
              fill="#102666"
            >
              <tspan x={0} y={241}>
                {"\xA30.8M"}
              </tspan>
            </text>
            <text
              fontFamily="Ubuntu-Regular, Ubuntu"
              fontSize={20}
              fontWeight="normal"
              fill="#102666"
            >
              <tspan x={0} y={315}>
                {"\xA30.6M"}
              </tspan>
            </text>
            <text
              fontFamily="Ubuntu-Regular, Ubuntu"
              fontSize={20}
              fontWeight="normal"
              fill="#102666"
            >
              <tspan x={0} y={389}>
                {"\xA30.4M"}
              </tspan>
            </text>
            <text
              fontFamily="Ubuntu-Regular, Ubuntu"
              fontSize={20}
              fontWeight="normal"
              fill="#102666"
            >
              <tspan x={0} y={463}>
                {"\xA30.2M"}
              </tspan>
            </text>
            <path
              strokeOpacity={0.2}
              stroke="#D8D8D8"
              strokeWidth={5}
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M71 12L1180 12"
            />
            <path
              strokeOpacity={0.2}
              stroke="#D8D8D8"
              strokeWidth={5}
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M71 86.5L1180 86.5"
            />
            <path
              strokeOpacity={0.2}
              stroke="#D8D8D8"
              strokeWidth={5}
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M71 160.5L1180 160.5"
            />
            <path
              strokeOpacity={0.2}
              stroke="#D8D8D8"
              strokeWidth={5}
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M71 234.5L1180 234.5"
            />
            <path
              strokeOpacity={0.2}
              stroke="#D8D8D8"
              strokeWidth={5}
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M71 308.5L1180 308.5"
            />
            <path
              strokeOpacity={0.2}
              stroke="#D8D8D8"
              strokeWidth={5}
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M71 382.5L1180 382.5"
            />
            <path
              strokeOpacity={0.2}
              stroke="#D8D8D8"
              strokeWidth={5}
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M71 456.5L1180 456.5"
            />
            <mask fill="#fff" transform="translate(91 529.393) translate(0 .308)">
              <use xlinkHref="#a" />
            </mask>
            <path
              stroke="#D8D8D8"
              strokeWidth={5}
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M71.5 532.5L1187.5 532.5"
            />
            <path
              stroke="#102666"
              strokeWidth={5}
              fill="#102666"
              strokeLinejoin="round"
              d="M94 532L122.934936 532 122.934936 439 94 439z"
            />
            <path
              stroke="#102666"
              strokeWidth={5}
              fill="#D74EA5"
              strokeLinejoin="round"
              d="M149 532L177.934936 532 177.934936 498 149 498z"
            />
            <path
              stroke="#102666"
              strokeWidth={5}
              fill="#0079B9"
              d="M120 532L148.934936 532 148.934936 361 120 361z"
            />
            <path
              stroke="#102666"
              strokeWidth={5}
              fill="#102666"
              strokeLinejoin="round"
              d="M204 532L232.934936 532 232.934936 439 204 439z"
            />
            <path
              stroke="#102666"
              strokeWidth={5}
              fill="#D74EA5"
              strokeLinejoin="round"
              d="M259 532L287.934936 532 287.934936 491 259 491z"
            />
            <path
              stroke="#102666"
              strokeWidth={5}
              fill="#0079B9"
              d="M230 532L258.934936 532 258.934936 337 230 337z"
            />
            <path
              stroke="#102666"
              strokeWidth={5}
              fill="#102666"
              strokeLinejoin="round"
              d="M314 532L342.934936 532 342.934936 451 314 451z"
            />
            <path
              stroke="#102666"
              strokeWidth={5}
              fill="#D74EA5"
              strokeLinejoin="round"
              d="M369 532L397.934936 532 397.934936 482 369 482z"
            />
            <path
              stroke="#102666"
              strokeWidth={5}
              fill="#0079B9"
              d="M340 532L368.934936 532 368.934936 348 340 348z"
            />
            <path
              stroke="#102666"
              strokeWidth={5}
              fill="#102666"
              strokeLinejoin="round"
              d="M425 532L453.934936 532 453.934936 423 425 423z"
            />
            <path
              stroke="#102666"
              strokeWidth={5}
              fill="#D74EA5"
              strokeLinejoin="round"
              d="M480 532L508.934936 532 508.934936 432 480 432z"
            />
            <path
              stroke="#102666"
              strokeWidth={5}
              fill="#0079B9"
              d="M451 532L479.934936 532 479.934936 281 451 281z"
            />
            <path
              stroke="#102666"
              strokeWidth={5}
              fill="#102666"
              strokeLinejoin="round"
              d="M536 532L564.934936 532 564.934936 409 536 409z"
            />
            <path
              stroke="#102666"
              strokeWidth={5}
              fill="#D74EA5"
              strokeLinejoin="round"
              d="M591 532L619.934936 532 619.934936 385 591 385z"
            />
            <path
              stroke="#102666"
              strokeWidth={5}
              fill="#0079B9"
              d="M562 532L590.934936 532 590.934936 180 562 180z"
            />
            <path
              stroke="#102666"
              strokeWidth={5}
              fill="#102666"
              strokeLinejoin="round"
              d="M643 531.933977L671.934372 531.933977 671.934372 385 643 385z"
            />
            <path
              stroke="#102666"
              strokeWidth={5}
              fill="#D74EA5"
              strokeLinejoin="round"
              d="M701 532L729.934936 532 729.934936 366 701 366z"
            />
            <path
              stroke="#102666"
              strokeWidth={5}
              fill="#0079B9"
              d="M672 532.004923L700.934936 532.004923 700.934936 122 672 122z"
            />
            <path
              stroke="#102666"
              strokeWidth={5}
              fill="#102666"
              strokeLinejoin="round"
              d="M754 532L782.934936 532 782.934936 356 754 356z"
            />
            <path
              stroke="#102666"
              strokeWidth={5}
              fill="#D74EA5"
              strokeLinejoin="round"
              d="M812 532L840.934936 532 840.934936 348 812 348z"
            />
            <path
              stroke="#102666"
              strokeWidth={5}
              fill="#0079B9"
              d="M783 532L811.934936 532 811.934936 92 783 92z"
            />
            <path
              stroke="#102666"
              strokeWidth={5}
              fill="#102666"
              strokeLinejoin="round"
              d="M864 532L892.934936 532 892.934936 351 864 351z"
            />
            <path
              stroke="#102666"
              strokeWidth={5}
              fill="#D74EA5"
              strokeLinejoin="round"
              d="M922 532L950.934936 532 950.934936 356 922 356z"
            />
            <path
              stroke="#102666"
              strokeWidth={5}
              fill="#0079B9"
              d="M893 532L921.934372 532 921.934372 92 893 92z"
            />
            <path
              stroke="#102666"
              strokeWidth={5}
              fill="#102666"
              strokeLinejoin="round"
              d="M973 532L1001.93494 532 1001.93494 356 973 356z"
            />
            <path
              stroke="#102666"
              strokeWidth={5}
              fill="#D74EA5"
              strokeLinejoin="round"
              d="M1031 532L1059.93494 532 1059.93494 354 1031 354z"
            />
            <path
              stroke="#102666"
              strokeWidth={5}
              fill="#0079B9"
              d="M1002 531.91523L1030.93437 531.91523 1030.93437 97 1002 97z"
            />
            <path
              stroke="#102666"
              strokeWidth={5}
              fill="#102666"
              strokeLinejoin="round"
              d="M1085 532L1113.93494 532 1113.93494 340 1085 340z"
            />
            <path
              stroke="#102666"
              strokeWidth={5}
              fill="#D74EA5"
              strokeLinejoin="round"
              d="M1143 532L1171.93437 532 1171.93437 319 1143 319z"
            />
            <path
              stroke="#102666"
              strokeWidth={5}
              fill="#0079B9"
              d="M1114 532L1142.93494 532 1142.93494 64 1114 64z"
            />
          </g>
        </g>
      </svg>
    </div>
  );
}

export default SvgComponent;
