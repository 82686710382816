import * as React from "react";
import styles from '../styles/breakdown-3.module.scss';

function SvgComponent(props) {
  return (
    <div className={styles.svg}>
      <svg viewBox="0 0 97 97" {...props}>
      <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <path
          d="M79.158 14.228C71.02 6.936 60.27 2.5 48.484 2.5l-.097.001v28.746l.097-.002c4.37 0 8.356 1.628 11.394 4.307a17.356 17.356 0 012.353 2.531l23.163-17.028a46.2 46.2 0 00-6.236-6.827"
          fill="#D8D8D8"
          transform="translate(-739 -2033) translate(739 2033)"
        />
        <path
          d="M50.888 28.9a19.69 19.69 0 0111.723 5.803l19.217-14.13a44.139 44.139 0 00-4.337-4.483A43.398 43.398 0 0050.888 5.066V28.9zm11.346 11.684a2.496 2.496 0 01-1.994-.99 14.882 14.882 0 00-2.014-2.166 14.717 14.717 0 00-9.741-3.683c-1.381 0-2.597-1.116-2.597-2.497V2.5a2.5 2.5 0 012.5-2.5h.004c12.053 0 23.535 4.391 32.434 12.366a48.867 48.867 0 016.574 7.195 2.497 2.497 0 01-.525 3.506l-23.163 17.03a2.484 2.484 0 01-1.478.486z"
          fill="#102666"
          transform="translate(-739 -2033) translate(739 2033)"
        />
        <path
          d="M85.394 21.055L62.231 38.084a17.16 17.16 0 013.494 10.4c0 1.568-.213 3.084-.604 4.527-1.99 7.325-8.682 12.714-16.636 12.714-9.523 0-17.24-7.72-17.24-17.24 0-9.498 7.68-17.201 17.17-17.238L48.462 2.5C23.076 2.513 2.498 23.096 2.498 48.485c0 25.396 20.588 45.984 45.985 45.984 21.107 0 38.892-14.223 44.297-33.606a46.036 46.036 0 001.688-12.378 45.78 45.78 0 00-9.075-27.43"
          fill="#0079B9"
          transform="translate(-739 -2033) translate(739 2033)"
        />
        <path
          d="M45.957 5.073C23.153 6.387 5 25.36 5 48.485c0 23.977 19.507 43.484 43.484 43.484 19.447 0 36.671-13.067 41.889-31.778a43.559 43.559 0 001.596-11.706c0-8.594-2.465-16.806-7.153-23.903L65.627 38.69a19.553 19.553 0 012.598 9.795c0 1.747-.233 3.49-.692 5.18-2.327 8.573-10.16 14.56-19.049 14.56-10.884 0-19.74-8.855-19.74-19.74 0-10 7.503-18.308 17.175-19.572l.038-23.84zm2.527 91.896C21.75 96.969 0 75.219 0 48.485 0 21.763 21.739.013 48.46 0h.001c.663 0 1.3.264 1.768.734.47.469.733 1.106.732 1.77l-.046 28.747a2.5 2.5 0 01-2.49 2.496c-8.095.032-14.681 6.643-14.681 14.738 0 8.128 6.612 14.74 14.74 14.74 6.638 0 12.487-4.47 14.223-10.87.344-1.263.518-2.566.518-3.87 0-3.24-1.033-6.314-2.986-8.891a2.498 2.498 0 01.512-3.524l23.163-17.03a2.5 2.5 0 013.486.522c6.26 8.41 9.569 18.41 9.569 28.923 0 4.426-.599 8.817-1.78 13.049-5.818 20.864-25.023 35.435-46.705 35.435z"
          fill="#102666"
          transform="translate(-739 -2033) translate(739 2033)"
        />
      </g>
    </svg>
    </div>
  );
}

export default SvgComponent;
