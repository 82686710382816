import * as React from "react";
import styles from '../styles/assets.module.scss';

function SvgComponent(props) {
  return (
    <div className={styles.svg}>
      <svg viewBox="0 0 143 129" {...props} className="animate animate--draw">
        <g
          transform="translate(-537 -1177) translate(498 1131) translate(42.168 49.449)"
          stroke="none"
          strokeWidth={1}
          fill="none"
          fillRule="evenodd"
        >
          <path
            pathLength="1"
            stroke="#102666"
            strokeWidth={5}
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M9.6967 122.353L127.9447 122.353 127.9447 61.748 9.6967 61.748z"
          />
          <g transform="translate(87.585 71.984)">
            <path
              pathLength="1"
              fill="#102666"
              d="M0 49.511L24.512 49.511 24.512 -1.42108547e-14 0 -1.42108547e-14z"
            />
            <path
              pathLength="1"
              stroke="#102666"
              strokeWidth={5}
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M0 49.511L24.512 49.511 24.512 -1.42108547e-14 0 -1.42108547e-14z"
            />
          </g>
          <path
            pathLength="1"
            stroke="#102666"
            strokeWidth={5}
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M25.9697 102.482L68.8207 102.482 68.8207 75.154 25.9697 75.154z"
          />
          <g transform="translate(0 20.267)">
            <path
              pathLength="1"
              fill="#DB8725"
              d="M122.484 -1.13686838e-13L13.737 -1.13686838e-13 5.68434189e-14 41.481 136.916 41.481z"
            />
            <path
              pathLength="1"
              stroke="#102666"
              strokeWidth={5}
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M122.484 -1.13686838e-13L13.737 -1.13686838e-13 5.68434189e-14 41.481 136.916 41.481z"
            />
          </g>
          <path
            pathLength="1"
            stroke="#102666"
            strokeWidth={5}
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M95.0437 19.811L112.0967 19.811 112.0967 8.52651283e-14 95.0437 8.52651283e-14z"
          />
          <g transform="translate(45.979 75.354)">
            <path pathLength="1" fill="#FFF" d="M1.4164 0.6205L1.4164 25.8025" />
            <path
              pathLength="1"
              stroke="#102666"
              strokeWidth={5}
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M1.4164 0.6205L1.4164 25.8025"
            />
          </g>
        </g>
      </svg>
    </div>
  );
}

export default SvgComponent;
