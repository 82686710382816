import React from 'react'
import Close from '../../svgs/close.js'
import Suitcase from '../svgs/suitcase.js'
import Bars1 from '../svgs/modal-1-bars-1.js'
import Bars2 from '../svgs/modal-1-bars-2.js'

const Modal = (props) => {
  return (
    <div className={`modal ${props.activeModal === 'results-modal-1' ? 'modal-is-active' : ''}`}>
      <div className="modal__container">
        <div className="modal__content">
          <div className="blocks">
            <div className="row row--blue row--j-e">
              <button className="modal__close" onClick={props.toggleModal}>
                <span className="visually-hidden">Close modal</span>
                <Close/>
              </button>
            </div>
            <div className="block block-l-6w blocks">
              <div className="block block--text pad pad--s pad--m">
                <h2 className="h-xl">Turnover’s effect on employment and wages</h2>
              </div>
              <div className="block block-4w icon pad">
                <Suitcase/>
              </div>
              <div className="block block-4w"></div>
              <div className="block block-4w block--pink"></div>
            </div>

            <div className="block block-l-6w block--text pad pad--s pad--m">
              <p>Futurebuilders investees in the Children and Young People sector have seen significant increases in employment, and even larger increases to wages. Although this was not the case for all sectors (the Education and Learning sector, for example), seeing these increases across the Futurebuilders portfolio further demonstrates that social investment can work efficiently to generate jobs and grow incomes.</p>
            </div>
            <div className="block block--text pad pad--s pad--m">
              <h3 className="h-m h-w-b h-mw">Turnover change compared to (i) Employment change and (ii) Wage change (3 year change from point of investment)</h3>
              <div className="modal__charts">
                <div className="modal__chart modal__chart--1">
                  <Bars1/>
                </div>
                <div className="modal__chart modal__chart--2">
                  <Bars2/>
                </div>
              </div>
            </div>
            <div className="row row--blue"></div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Modal
