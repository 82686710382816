import * as React from "react";
import styles from '../styles/piechart.module.scss'

function SvgComponent(props) {
  return (
    <div className={styles.svg}>
      <svg viewBox="0 0 583 460" {...props}>
        <defs>
          <path
            id="piechart-a"
            d="M0.0005 0.4855L424.673738 0.4855 424.673738 433 0.0005 433z"
          />
        </defs>
        <g
          transform="translate(-113 -1772) translate(113 1774.5)"
          stroke="none"
          strokeWidth={1}
          fill="none"
          fillRule="evenodd"
        >
          <path
            stroke="#102666"
            strokeWidth={5}
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M313 49.5L347.272242 15.5 546 15.5"
          />
          <path
            stroke="#102666"
            strokeWidth={5}
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M565.5 0L580.5 15.0005002 565.5 30"
          />
          <path
            stroke="#102666"
            strokeWidth={5}
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M565.5 128L580.5 143.0005 565.5 158"
          />
          <path
            stroke="#102666"
            strokeWidth={5}
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M373 94.5L467.007351 94.5 467.007351 143.5 546 143.5"
          />
          <path
            stroke="#102666"
            strokeWidth={5}
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M565 293L580 308.0005 565 323"
          />
          <path
            stroke="#102666"
            strokeWidth={5}
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M429.401297 262.5L518 262.5 518 352.5 398 352.5"
          />
          <path
            stroke="#102666"
            strokeWidth={5}
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M579 308L518 308"
          />
          <path
            d="M293.588 238.298c-9.242 34.05-40.353 59.102-77.33 59.102-44.26 0-80.14-35.88-80.14-80.14 0-44.152 35.707-79.958 79.819-80.132l.213-133.626C98.145 3.56 2.5 99.239 2.5 217.258c0 118.056 95.703 213.758 213.758 213.758 98.12 0 180.789-66.113 205.915-156.22l-128.585-36.498z"
            fill="#102666"
            transform="translate(0 23.5)"
          />
          <g transform="translate(0 23.5) translate(0 .516)">
            <mask id="piechart-b" fill="#fff">
              <use xlinkHref="#piechart-a" />
            </mask>
            <path
              d="M213.647 5.502c-55.47.668-107.52 22.59-146.806 61.895C26.96 107.293 5 160.333 5 216.743 5 333.23 99.769 428 216.257 428c47.071 0 91.612-15.146 128.804-43.8 35.364-27.245 61.578-65.596 74.02-108.198l-123.781-35.136c-10.532 34.583-42.676 58.518-79.043 58.518-45.568 0-82.64-37.072-82.64-82.64 0-44.554 35.583-81.094 79.822-82.585l.207-128.657zM216.257 433c-57.763 0-112.07-22.495-152.917-63.34C22.495 328.813 0 274.505 0 216.741c0-57.745 22.483-112.04 63.304-152.88C104.126 23.022 158.407.514 216.15.485h.001c.665 0 1.3.265 1.768.734.47.47.733 1.106.732 1.77l-.214 133.626a2.5 2.5 0 01-2.49 2.496c-42.638.169-77.327 34.995-77.327 77.633 0 42.81 34.828 77.64 77.638 77.64 34.96 0 65.769-23.546 74.918-57.258a2.506 2.506 0 013.096-1.75l128.584 36.5a2.5 2.5 0 011.726 3.076c-12.435 44.594-39.591 84.798-76.466 113.209C310.038 417.495 264.442 433 216.257 433z"
              fill="#102666"
              mask="url(#peichart-b)"
            />
          </g>
          <path
            d="M216.258 137.12c20.31 0 38.843 7.57 52.966 20.023l89.62-99.124C321.016 24.123 271.048 3.5 216.258 3.5c-.15 0-.298.006-.447.006v133.625c.149-.001.298-.011.447-.011"
            fill="#DB8725"
            transform="translate(0 23.5)"
          />
          <path
            d="M218.31 134.645A82.522 82.522 0 01269 153.662l86.295-95.446C317.3 24.976 268.829 6.497 218.31 6.01v128.635zm50.913 24.998a2.486 2.486 0 01-1.653-.625 77.54 77.54 0 00-51.313-19.398l-.43.011h-.017a2.497 2.497 0 01-2.5-2.5V3.505a2.5 2.5 0 012.5-2.5l.447-.005c53.33 0 104.56 19.588 144.255 55.157a2.498 2.498 0 01.187 3.538l-89.62 99.124a2.495 2.495 0 01-1.856.824z"
            fill="#102666"
            transform="translate(0 23.5)"
          />
          <path
            d="M280.162 168.91l107.671-79.158a215.004 215.004 0 00-28.989-31.733l-89.62 99.124a80.529 80.529 0 0110.938 11.767"
            fill="#A5CB25"
            transform="translate(0 23.5)"
          />
          <path
            d="M272.75 156.97a83.245 83.245 0 017.887 8.488l103.676-76.222a212.917 212.917 0 00-25.293-27.683l-86.27 95.417zm7.414 14.44a2.495 2.495 0 01-1.994-.99 78.22 78.22 0 00-10.6-11.402 2.503 2.503 0 01-.201-3.552l89.62-99.124a2.502 2.502 0 013.523-.185 218.02 218.02 0 0129.327 32.102 2.503 2.503 0 01-.526 3.507l-107.67 79.158a2.487 2.487 0 01-1.479.486z"
            fill="#102666"
            transform="translate(0 23.5)"
          />
          <path
            d="M387.833 89.752L280.162 168.91c10.185 13.44 16.235 30.186 16.235 48.35 0 7.283-.989 14.33-2.809 21.038l128.585 36.498c5.104-18.307 7.843-37.602 7.843-57.538 0-47.787-15.685-91.91-42.183-127.506"
            fill="#0079B9"
            transform="translate(0 23.5)"
          />
          <path
            d="M296.612 236.558l123.813 35.144a211.837 211.837 0 007.09-54.444c0-44.97-13.895-87.793-40.215-124.012l-103.644 76.199c9.98 14.024 15.241 30.5 15.241 47.815 0 6.49-.767 12.97-2.285 19.298m125.561 40.738c-.229 0-.459-.03-.683-.094l-128.585-36.5a2.5 2.5 0 01-1.73-3.06 77.852 77.852 0 002.722-20.382c0-17.064-5.438-33.261-15.727-46.84a2.498 2.498 0 01.511-3.524l107.671-79.159a2.502 2.502 0 013.487.523c27.92 37.505 42.676 82.114 42.676 128.998 0 19.742-2.67 39.326-7.934 58.21a2.5 2.5 0 01-2.408 1.828"
            fill="#102666"
            transform="translate(0 23.5)"
          />
          <path
            stroke="#102666"
            strokeWidth={5}
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M580 15.5L546 15.5"
          />
          <path
            stroke="#102666"
            strokeWidth={5}
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M580 143.5L546 143.5"
          />
        </g>
      </svg>
    </div>
  );
}

export default SvgComponent;
