import * as React from "react";
import styles from '../styles/right-down-arrow.module.scss'

function SvgComponent(props) {
  return (
    <div className={styles.svg}>
      <svg viewBox="0 0 291 79" {...props}>
        <g
          transform="translate(-976 -2043) translate(979 2046)"
          stroke="#102666"
          strokeWidth={5}
          fill="none"
          fillRule="evenodd"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="M285 58L269.9995 73 255 58" />
          <path d="M0 0L270 0 270 73" />
        </g>
      </svg>
    </div>
  );
}

export default SvgComponent;
