import React from 'react'
import Close from '../../svgs/close.js'
import Bars1 from '../svgs/modal-2-bars-1.js'
import Bars2 from '../svgs/modal-2-bars-2.js'

const Modal = (props) => {
  return (
    <div className={`modal ${props.activeModal === 'patience-modal-2' ? 'modal-is-active' : ''}`}>
      <div className="modal__container">
        <div className="modal__content">
          <div className="blocks">
            <div className="row row--green row--j-e">
              <button className="modal__close" onClick={props.toggleModal}>
                <span className="visually-hidden">Close modal</span>
                <Close/>
              </button>
            </div>
            <div className="block block-l-6w block--text block--text-j-sb pad pad--m">
              <h3 className="h-m h-w-b">IMD 2010 Quintiles (by number of investees)</h3>
              <Bars1/>
              <div className="modal__notes">
                <p>*IMD 5= Most deprived</p>
                <p>Note: some postcodes registered might reflect head offices and not areas of delivery</p>
              </div>
            </div>
            <div className="block block-l-6w block--text block--text-j-sb pad pad--m">
              <h3 className="h-m h-w-b">IMD 2010 Quintiles (by value disbursed)</h3>
              <Bars2/>
              <div className="modal__notes">
                <p>*IMD 5= Most deprived</p>
                <p>Note: some postcodes registered might reflect head offices and not areas of delivery</p>
              </div>
            </div>
            <div className="row row--green"></div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Modal
