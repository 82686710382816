import * as React from "react";
import styles from '../styles/employees.module.scss';

function SvgComponent(props) {
  return (
    <div className={styles.svg}>
      <svg viewBox="0 0 142 133" {...props} className="animate animate--draw">
        <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
          <path
            pathLength="1"
            d="M67.443 74.154c-1.136-4.444-2.28-8.915-4.18-13.09-1.747-3.837-4.115-7.38-6.88-10.562-1.013-1.167-2.099-2.302-3.433-3.081-1.738-1.016-3.778-1.362-5.775-1.616a82.207 82.207 0 00-14.124-.572l-3.547 1.752c-4.175.333-8.429.684-12.303 2.277-6.98 2.87-11.869 9.52-14.307 16.664C.456 73.07.152 80.745.047 88.293a486.88 486.88 0 00.712 33.939c24.137 4.069 48.897 1.94 73.094-1.754 1.38-15.607-2.53-31.144-6.41-46.324"
            fill="#A5CB25"
            transform="translate(-317 -1173) translate(56 1131) translate(264 45)"
          />
          <path
            pathLength="1"
            d="M67.443 74.154c-1.136-4.444-2.28-8.915-4.18-13.09-1.747-3.837-4.115-7.38-6.88-10.562-1.013-1.167-2.099-2.302-3.433-3.081-1.738-1.016-3.778-1.362-5.775-1.616a82.207 82.207 0 00-14.124-.572l-3.547 1.752c-4.175.333-8.429.684-12.303 2.277-6.98 2.87-11.869 9.52-14.307 16.664C.456 73.07.152 80.745.047 88.293a486.88 486.88 0 00.712 33.939c24.137 4.069 48.897 1.94 73.094-1.754 1.38-15.607-2.53-31.144-6.41-46.324z"
            stroke="#102666"
            strokeWidth={5}
            strokeLinecap="round"
            strokeLinejoin="round"
            transform="translate(-317 -1173) translate(56 1131) translate(264 45)"
          />
          <path
            pathLength="1"
            d="M25.797 37.84c.7.551.829 1.545.904 2.435.258 3.075.535 6.197 1.637 9.08 1.102 2.883 3.154 5.546 6.027 6.672 2.874 1.126 6.584.331 8.225-2.283 1.385-2.204 1.066-5.08.224-7.543-.334-.974-.746-2.017-.44-3 .21-.67.724-1.194 1.194-1.716 5.882-6.52 6.713-17.064 1.925-24.427-1.117-1.718-2.556-3.306-4.428-4.139-1.71-.76-3.635-.833-5.505-.862-3.318-.05-6.814.047-9.668 1.74-2.692 1.595-4.455 4.44-5.382 7.428-.98 3.161-2.042 8.098-1.106 11.363.327 1.137 1.319 2.77 2.2 3.525 1.279 1.102 2.976.771 4.193 1.727"
            fill="#DB8725"
            transform="translate(-317 -1173) translate(56 1131) translate(264 45)"
          />
          <path
            pathLength="1"
            d="M25.797 37.84c.7.551.829 1.545.904 2.435.258 3.075.535 6.197 1.637 9.08 1.102 2.883 3.154 5.546 6.027 6.672 2.874 1.126 6.584.331 8.225-2.283 1.385-2.204 1.066-5.08.224-7.543-.334-.974-.746-2.017-.44-3 .21-.67.724-1.194 1.194-1.716 5.882-6.52 6.713-17.064 1.925-24.427-1.117-1.718-2.556-3.306-4.428-4.139-1.71-.76-3.635-.833-5.505-.862-3.318-.05-6.814.047-9.668 1.74-2.692 1.595-4.455 4.44-5.382 7.428-.98 3.161-2.042 8.098-1.106 11.363.327 1.137 1.319 2.77 2.2 3.525 1.279 1.102 2.976.771 4.193 1.727z"
            stroke="#102666"
            strokeWidth={5}
            strokeLinejoin="round"
            transform="translate(-317 -1173) translate(56 1131) translate(264 45)"
          />
          <path
            pathLength="1"
            d="M13.402 27.465c-2.113-3.072-3.43-7.573-.87-10.284.765-.809 1.801-1.352 2.448-2.257 1.36-1.899.623-4.805 2.14-6.582 1.613-1.889 4.588-1.332 7.032-1.772C29.204 5.661 32.713.038 37.845 0c3.427-.025 6.436 2.662 7.715 5.841 1.28 3.18 1.136 6.747.656 10.141-.082.582-.186 1.194-.559 1.65-.529.644-1.44.798-2.27.874a29.943 29.943 0 01-7.198-.219c-2.44-.372-4.895-1.048-7.342-.708-2.445.34-4.938 2.085-5.11 4.548-.178 2.536 2.566 4.681 1.562 7.152-.937 2.307-4.137 2.44-4.99 4.99-2.744-1.586-5.114-4.199-6.907-6.804"
            fill="#0079B9"
            transform="translate(-317 -1173) translate(56 1131) translate(264 45)"
          />
          <path
            pathLength="1"
            d="M13.402 27.465c-2.113-3.072-3.43-7.573-.87-10.284.765-.809 1.801-1.352 2.448-2.257 1.36-1.899.623-4.805 2.14-6.582 1.613-1.889 4.588-1.332 7.032-1.772C29.204 5.661 32.713.038 37.845 0c3.427-.025 6.436 2.662 7.715 5.841 1.28 3.18 1.136 6.747.656 10.141-.082.582-.186 1.194-.559 1.65-.529.644-1.44.798-2.27.874a29.943 29.943 0 01-7.198-.219c-2.44-.372-4.895-1.048-7.342-.708-2.445.34-4.938 2.085-5.11 4.548-.178 2.536 2.566 4.681 1.562 7.152-.937 2.307-4.137 2.44-4.99 4.99-2.744-1.586-5.114-4.199-6.907-6.804z"
            stroke="#102666"
            strokeWidth={5}
            strokeLinecap="round"
            strokeLinejoin="round"
            transform="translate(-317 -1173) translate(56 1131) translate(264 45)"
          />
          <path
            pathLength="1"
            d="M76.049 55.825c-1.65.064-3.6-.25-4.357-1.717-.471-.911-.326-2.026.04-2.983.366-.958.932-1.827 1.312-2.78 1.183-2.966.437-6.37 1.235-9.461.132-.51.333-1.045.768-1.34.345-.232.778-.275 1.19-.311l1.913-.167c.428-.037.885-.07 1.262.137.353.193.57.56.77.909 1.55 2.704 3.108 5.424 4.165 8.356.513 1.423.903 2.896 1.106 4.397.112.82.638 3.877-.086 4.474-.51.422-3.437-.062-4.211-.001-1.705.135-3.396.42-5.107.487"
            fill="#102666"
            transform="translate(-317 -1173) translate(56 1131) translate(264 45)"
          />
          <path
            pathLength="1"
            d="M127.085 60.615c2.039 3.159 2.844 6.935 3.61 10.617 3.53 16.948 7.078 34.208 5.43 51.442-3.834 2.018-8.203 2.799-12.504 3.25-3.641.383-7.302.557-10.962.617-17.138.282-34.3.653-51.423-.107-2.471-.11-4.586-1.885-5.077-4.31-2.117-10.44-.05-21.492 1.729-32.123 1.002-5.987 1.919-12.048 4.135-17.7 2.217-5.65 5.894-10.95 11.203-13.896 3.604-2.001 7.735-2.81 11.805-3.47 8.519-1.382 17.25-2.302 25.88-1.584 6.018.5 12.635 1.782 16.174 7.264"
            fill="#0079B9"
            transform="translate(-317 -1173) translate(56 1131) translate(264 45)"
          />
          <path
            pathLength="1"
            d="M127.085 60.615c2.039 3.159 2.844 6.935 3.61 10.617 3.53 16.948 7.078 34.208 5.43 51.442-3.834 2.018-8.203 2.799-12.504 3.25-3.641.383-7.302.557-10.962.617-17.138.282-34.3.653-51.423-.107-2.471-.11-4.586-1.885-5.077-4.31-2.117-10.44-.05-21.492 1.729-32.123 1.002-5.987 1.919-12.048 4.135-17.7 2.217-5.65 5.894-10.95 11.203-13.896 3.604-2.001 7.735-2.81 11.805-3.47 8.519-1.382 17.25-2.302 25.88-1.584 6.018.5 12.635 1.782 16.174 7.264z"
            stroke="#102666"
            strokeWidth={5}
            strokeLinecap="round"
            strokeLinejoin="round"
            transform="translate(-317 -1173) translate(56 1131) translate(264 45)"
          />
          <path
            pathLength="1"
            d="M72.481 31.342c.562-2.632 1.832-5.075 3.38-7.29 1.781-2.55 4.013-4.892 6.843-6.23 4.378-2.073 9.68-1.459 14.076.574 4.395 2.033 8.045 5.34 11.492 8.715 2.197 2.152 4.421 4.482 5.268 7.422 1.054 3.658-.265 7.667-2.57 10.712-1.359 1.794-3.035 3.306-4.805 4.693-1.135.89-3.27 1.76-4.06 2.966-1.919 2.93.001 6.798-3.328 9.453-1.928 1.538-4.603 1.728-7.077 1.628-1.94-.079-3.988-.337-5.517-1.518-3.315-2.56-2.44-7.586-2.959-11.712-.059-.476-.147-.96-.397-1.372-.448-.732-1.313-1.083-2.092-1.454-6.052-2.885-9.638-10.09-8.254-16.587"
            fill="#D74EA5"
            transform="translate(-317 -1173) translate(56 1131) translate(264 45)"
          />
          <path
            pathLength="1"
            d="M72.481 31.342c.562-2.632 1.832-5.075 3.38-7.29 1.781-2.55 4.013-4.892 6.843-6.23 4.378-2.073 9.68-1.459 14.076.574 4.395 2.033 8.045 5.34 11.492 8.715 2.197 2.152 4.421 4.482 5.268 7.422 1.054 3.658-.265 7.667-2.57 10.712-1.359 1.794-3.035 3.306-4.805 4.693-1.135.89-3.27 1.76-4.06 2.966-1.919 2.93.001 6.798-3.328 9.453-1.928 1.538-4.603 1.728-7.077 1.628-1.94-.079-3.988-.337-5.517-1.518-3.315-2.56-2.44-7.586-2.959-11.712-.059-.476-.147-.96-.397-1.372-.448-.732-1.313-1.083-2.092-1.454-6.052-2.885-9.638-10.09-8.254-16.587z"
            stroke="#102666"
            strokeWidth={5}
            transform="translate(-317 -1173) translate(56 1131) translate(264 45)"
          />
          <path
            pathLength="1"
            d="M73.32 22.882c1.806-5.357 6.457-9.542 11.835-11.284 5.38-1.743 11.37-1.166 16.561 1.073 5.531 2.385 9.44 7.138 12.416 12.373 1.07 1.878 2.153 3.794 2.594 5.909.528 2.526.212 5.459 1.961 7.357 1.941 2.105 5.61 1.923 7.39 4.166 1.158 1.458 1.127 3.424 1.195 5.33-.024.295-.007.607.047.93l.025.317c.042.454.114.9.204 1.344.13 4.7-5.582 4.487-9.384 4.799-1.672.137-3.152.56-4.539 1.159-4.19.027-8.4.299-12.568.579a121.568 121.568 0 01-.41-16.238c2.061.672 4.556.042 5.851-1.697 1.295-1.74 1.081-4.504-.624-5.845-1.705-1.34-4.644-.726-5.476 1.277-2.796.298-5.705-1.108-7.209-3.484-2.152-3.4-2.375-9.338-5.85-11.363-1.47 2.613-3.386 5.02-5.84 6.742-2.455 1.72-5.486 2.714-8.468 2.413-.628-1.888-.348-3.972.288-5.857"
            fill="#102666"
            transform="translate(-317 -1173) translate(56 1131) translate(264 45)"
          />
          <path
            pathLength="1"
            d="M73.32 22.882c1.806-5.357 6.457-9.542 11.835-11.284 5.38-1.743 11.37-1.166 16.561 1.073 5.531 2.385 9.44 7.138 12.416 12.373 1.07 1.878 2.153 3.794 2.594 5.909.528 2.526.212 5.459 1.961 7.357 1.941 2.105 5.61 1.923 7.39 4.166 1.158 1.458 1.127 3.424 1.195 5.33-.024.295-.007.607.047.93l.025.317c.042.454.114.9.204 1.344.13 4.7-5.582 4.487-9.384 4.799-1.672.137-3.152.56-4.539 1.159-4.19.027-8.4.299-12.568.579a121.568 121.568 0 01-.41-16.238c2.061.672 4.556.042 5.851-1.697 1.295-1.74 1.081-4.504-.624-5.845-1.705-1.34-4.644-.726-5.476 1.277-2.796.298-5.705-1.108-7.209-3.484-2.152-3.4-2.375-9.338-5.85-11.363-1.47 2.613-3.386 5.02-5.84 6.742-2.455 1.72-5.486 2.714-8.468 2.413-.628-1.888-.348-3.972.288-5.857z"
            stroke="#102666"
            strokeWidth={5}
            strokeLinecap="round"
            strokeLinejoin="round"
            transform="translate(-317 -1173) translate(56 1131) translate(264 45)"
          />
        </g>
      </svg>
    </div>
  );
}

export default SvgComponent;
