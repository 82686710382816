import * as React from "react";
import styles from '../styles/breakdown-4.module.scss';

function SvgComponent(props) {
  return (
    <div className={styles.svg}>
      <svg viewBox="0 0 97 97" {...props}>
      <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <path
          d="M65.12 53.01c-1.988 7.325-8.68 12.714-16.636 12.714-9.52 0-17.24-7.718-17.24-17.239 0-9.499 7.682-17.202 17.171-17.239L48.462 2.5C23.075 2.512 2.5 23.095 2.5 48.484c0 25.397 20.588 45.984 45.984 45.984 21.11 0 38.893-14.222 44.298-33.606L65.12 53.01z"
          fill="#0079B9"
          transform="translate(-739 -2165) translate(739 2165)"
        />
        <path
          d="M45.958 5.073C23.153 6.387 5 25.36 5 48.485c0 23.977 19.507 43.484 43.484 43.484 18.613 0 35.19-11.97 41.153-29.4l-22.923-6.507c-3.023 7.27-10.194 12.163-18.23 12.163-10.884 0-19.74-8.855-19.74-19.74 0-10 7.503-18.308 17.175-19.572l.039-23.84zm2.526 91.896C21.75 96.969 0 75.219 0 48.485 0 21.763 21.739.013 48.461 0h.001c.663 0 1.3.264 1.768.734.47.469.733 1.107.732 1.77l-.047 28.747a2.5 2.5 0 01-2.49 2.496c-8.095.032-14.681 6.643-14.681 14.738 0 8.128 6.612 14.74 14.74 14.74 6.638 0 12.487-4.47 14.223-10.87a2.508 2.508 0 011.183-1.521 2.491 2.491 0 011.913-.228l27.662 7.852a2.498 2.498 0 011.725 3.076c-5.818 20.864-25.024 35.435-46.706 35.435z"
          fill="#102666"
          transform="translate(-739 -2165) translate(739 2165)"
        />
        <path
          d="M85.394 21.055a46.305 46.305 0 00-6.236-6.827C71.02 6.937 60.273 2.5 48.485 2.5h-.096v28.747l.096-.002c4.37 0 8.356 1.628 11.395 4.308a17.26 17.26 0 012.354 2.53 17.165 17.165 0 013.492 10.402c0 1.567-.213 3.083-.604 4.526l27.662 7.852a46.076 46.076 0 001.686-12.378 45.78 45.78 0 00-9.075-27.43"
          fill="#D8D8D8"
          transform="translate(-739 -2165) translate(739 2165)"
        />
        <path
          d="M68.031 51.238l22.946 6.514c.658-3.034.991-6.14.991-9.267 0-9.428-2.966-18.397-8.58-25.937v-.001a43.814 43.814 0 00-5.898-6.457A43.4 43.4 0 0050.888 5.066V28.9a19.685 19.685 0 0110.644 4.777 19.854 19.854 0 012.692 2.897 19.56 19.56 0 014 11.91c0 .922-.064 1.842-.193 2.754m24.751 12.125c-.229 0-.459-.032-.683-.096l-27.662-7.852a2.5 2.5 0 01-1.73-3.06c.344-1.263.517-2.566.517-3.87 0-3.24-1.032-6.314-2.984-8.892a15.032 15.032 0 00-2.015-2.166 14.716 14.716 0 00-9.74-3.682c-1.382 0-2.597-1.117-2.597-2.498V2.5a2.5 2.5 0 012.5-2.5h.004c12.052 0 23.535 4.39 32.434 12.365a48.754 48.754 0 016.574 7.197c6.26 8.409 9.568 18.41 9.568 28.922 0 4.424-.598 8.815-1.778 13.049a2.502 2.502 0 01-2.408 1.829"
          fill="#102666"
          transform="translate(-739 -2165) translate(739 2165)"
        />
      </g>
    </svg>
    </div>
  );
}

export default SvgComponent;
