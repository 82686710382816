import * as React from "react";
import styles from '../styles/invest.module.scss';

function SvgComponent(props) {
  return (
    <div className={styles.svg}>
      <svg viewBox="0 0 307 277" {...props} className="animate animate--draw">
      <g
        transform="translate(-1 1)"
        stroke="none"
        strokeWidth={1}
        fill="none"
        fillRule="evenodd"
      >
        <path
          pathLength="1"
          fill="#102666"
          d="M0.4997 162.4831L42.3577 162.4831 42.3577 258.9841 0.4997 273.1061"
        />
        <path
          pathLength="1"
          stroke="#102666"
          strokeWidth={5}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M0.4997 162.4831L42.3577 162.4831 42.3577 258.9841 0.4997 273.1061"
        />
        <path
          pathLength="1"
          fill="#D74EA5"
          d="M264.2224 162.0984L264.2224 155.4294 264.2224 112.9154 264.2224 84.4944 235.8014 84.4944 98.6174 84.4944 69.7434 84.4944 69.7434 168.1954 209.6224 199.0624z"
        />
        <path
          pathLength="1"
          stroke="#102666"
          strokeWidth={4.024}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M264.2224 162.0984L264.2224 155.4294 264.2224 112.9154 264.2224 84.4944 235.8014 84.4944 98.6174 84.4944 69.7434 84.4944 69.7434 168.1954 209.6224 199.0624z"
        />
        <g transform="translate(42 .606)">
          <path
            pathLength="1"
            d="M222.22 112.313c-15.696 0-28.42-12.723-28.42-28.418M195.447 173.654c3.947-10.97 14.43-18.83 26.775-18.83M28.196 154.824c8.818 0 16.696 4.015 21.903 10.314M56.62 83.894c0 15.695-12.724 28.418-28.42 28.418M194.35 133.57a8.759 8.759 0 01-8.759 8.76 8.76 8.76 0 110-17.518 8.76 8.76 0 018.76 8.759zM158.43 133.57c0 18.473-14.976 33.447-33.447 33.447-18.472 0-33.447-14.974-33.447-33.446 0-18.472 14.975-33.446 33.447-33.446 18.471 0 33.446 14.974 33.446 33.446zM73.135 133.57a8.76 8.76 0 01-8.76 8.76 8.759 8.759 0 110-17.518 8.76 8.76 0 018.76 8.759z"
            stroke="#102666"
            strokeWidth={4.024}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            pathLength="1"
            d="M134.917 117.74c-1.298-1.148-3.161-1.329-4.89-1.456-2.41-.177-5.03-.3-7.026 1.06-2.542 1.734-3.128 5.195-3.245 8.27-.296 7.722.894 15.764-1.925 22.96h16.63"
            stroke="#102666"
            strokeWidth={4.024}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <g transform="translate(112 133)">
            <path
              pathLength="1" fill="#A5CB25" d="M0.6589 0.7238L18.2929 0.7238" />
            <path
              pathLength="1"
              stroke="#102666"
              strokeWidth={4.024}
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M0.6589 0.7238L18.2929 0.7238"
            />
          </g>
          <path
            pathLength="1"
            d="M.358 244.819l34.59-2.551a107.016 107.016 0 0119.335.325l33.636 7.85c12.85 2.999 26.185 3.774 39.208 1.639a107.001 107.001 0 0040.123-15.312c28.428-18.086 69.007-44.24 88.904-57.081 6.738-4.35 9.006-13.143 5.212-20.208-3.833-7.138-12.546-10.08-19.922-6.727l-79.257 36.026-1.292-5.535a12.551 12.551 0 00-11.305-7.055h-34.518c-20.997 0-29.396-5.14-29.396-5.14-12.194-4.552-24.389-6.08-35.577-5.912-8.112.1-15.688 1.092-22.356 2.452C11.65 170.832.917 176.157.917 176.157l-.559 68.662z"
            fill="#FFF"
          />
          <path
            pathLength="1"
            d="M.358 244.819l34.59-2.551a107.016 107.016 0 0119.335.325l33.636 7.85c12.85 2.999 26.185 3.774 39.208 1.639a107.001 107.001 0 0040.123-15.312c28.428-18.086 69.007-44.24 88.904-57.081 6.738-4.35 9.006-13.143 5.212-20.208-3.833-7.138-12.546-10.08-19.922-6.727l-79.257 36.026-1.292-5.535a12.551 12.551 0 00-11.305-7.055h-34.518c-20.997 0-29.396-5.14-29.396-5.14-12.194-4.552-24.389-6.08-35.577-5.912-8.112.1-15.688 1.092-22.356 2.452C11.65 170.832.917 176.157.917 176.157l-.559 68.662z"
            stroke="#102666"
            strokeWidth={5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            pathLength="1"
            d="M78.219 201.37h71.371c3.477 0 6.636-1.412 8.903-3.68a12.544 12.544 0 003.696-8.919 12.22 12.22 0 00-1.293-5.526 12.55 12.55 0 00-11.305-7.055h-34.519c-20.998 0-29.395-5.14-29.395-5.14-12.195-4.552-24.39-6.08-35.578-5.913-8.111.101-15.688 1.092-22.356 2.452C11.65 170.831.916 176.156.916 176.156"
            stroke="#102666"
            strokeWidth={5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            pathLength="1"
            stroke="#102666"
            strokeWidth={5}
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M24.9255 18.3798L42.4105 0.8938 59.8965 18.3798"
          />
          <path
            pathLength="1"
            stroke="#102666"
            strokeWidth={5}
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M42.4109 0.8942L42.4109 49.1862"
          />
          <path
            pathLength="1"
            stroke="#102666"
            strokeWidth={5}
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M108.1374 18.3798L125.6224 0.8938 143.1084 18.3798"
          />
          <path
            pathLength="1"
            stroke="#102666"
            strokeWidth={5}
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M125.6228 0.8942L125.6228 49.1862"
          />
          <path
            pathLength="1"
            stroke="#102666"
            strokeWidth={5}
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M191.3493 18.3798L208.8343 0.8938 226.3203 18.3798"
          />
          <path
            pathLength="1"
            stroke="#102666"
            strokeWidth={5}
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M208.8347 0.8942L208.8347 49.1862"
          />
        </g>
      </g>
    </svg>
    </div>
  );
}

export default SvgComponent;
